export default class Modal{
	constructor(){
		this.initialize();
	}
	initialize(){
		const modal = document.querySelector(".modal");
		const modalBg = document.querySelector(".modal__bg");

		this.isModalOpen = false;
		this.currentModal = {
			selector: null,
			dom: null
		}
		const openModal = (modalSelector=null)=>{
			if(!modalSelector) return false;
			const targetModal = document.querySelector(modalSelector);
			if(!targetModal) return false;
			this.isModalOpen = true;
			this.currentModal = {
				selector: modalSelector,
				dom: targetModal
			};
			if(targetModal.classList.contains("modal__dialogue")) modal.classList.add("dialogue");
			modal.classList.add("active");
			targetModal.classList.add("active");
			requestAnimationFrame(()=>{requestAnimationFrame(()=>{
				modalBg.style.opacity = 1;
			});});
		}
		const closeModal = ()=>{
			if(this.isModalOpen !== true) return false;
			const currentModal = this.currentModal.dom;
			modalBg.style.opacity = 0;
			currentModal.classList.remove("active");
			modalBg.addEventListener("transitionend", ()=>{
				modal.classList.remove("active");
				modal.classList.remove("dialogue");
			}, {once:true});
			this.isModalOpen = false
			this.currentModal = {
				selector: null,
				dom: null
			}
		}

		const startBusy = ()=>{
			modal.classList.add("busy");
		}
		const endBusy = ()=>{
			modal.classList.remove("busy");
		}

		modalBg.addEventListener("click", closeModal, false)

		this.closeModal = closeModal;
		this.openModal = openModal;
		this.startBusy = startBusy;
		this.endBusy = endBusy;

	}
}