import Editor from './_editor.js';
export default class Edit{
	constructor(){
		this.initialize();
	}
	initialize(){
		if(document.body.classList.contains("edit")){
			const editorRoot = document.querySelector("#main>.editor")
			const editor = new Editor(editorRoot)
			editor.InitAsEditor();
		}
	}
}