import  { throttle,debounce }  from 'throttle-debounce';
import Factory from './_factory.js';
export default class Editor{
	constructor(editor_root){
		this.Factory = new Factory();
		this.initialize(editor_root);
	}

	initialize(editor_root){
		let isInitialized = false;
		const root = document.documentElement;
		const ctrl = window.control;
		const wall = window.project.walls[window.control.currentWall];

		const editor = editor_root;
		const bound = editor_root.querySelector(".bound");
		const furniture = editor_root.querySelector(".furniture");
		const alloc = editor_root.querySelector(".allocate");
		const canvas = editor_root.querySelector(".canvas");
		const instance = editor_root.querySelector(".instance");
		const ghostDesign = bound.querySelector(".design");

		this.isEditor = true;
		this.currentErrors = [];
		this.variations = [];

		wall.wallpaper.tolerance.actual.max = (wall.wallpaper.tolerance.actual.max===true) ? Number.MAX_SAFE_INTEGER-1 : wall.wallpaper.tolerance.actual.max;
		wall.wallpaper.tolerance.variable.width.max = (wall.wallpaper.tolerance.variable.width.max===true) ? Number.MAX_SAFE_INTEGER-1 : wall.wallpaper.tolerance.variable.width.max;
		wall.wallpaper.tolerance.variable.height.max = (wall.wallpaper.tolerance.variable.height.max===true) ? Number.MAX_SAFE_INTEGER-1 : wall.wallpaper.tolerance.variable.height.max;

		/*
			表示切り替えボタン
		*/
		let isScaleVisible = true;
		let isAllocVisible = true;
		let isFurnitureVisible = wall.settings.isFurnitureVisible;
		const toggleScaleBtn = document.querySelector(".visibility_settings__wallscale");
		const toggleAllocBtn = document.querySelector(".visibility_settings__alloc");
		const toggleFurnitureBtn = document.querySelector(".visibility_settings__furniture");
		const toggleScaleHandler = (bool)=>{
			isScaleVisible = bool;
			editor.setAttribute("data-scale-visible", isScaleVisible);
		}
		const toggleAllocHandler = (bool)=>{
			isAllocVisible = bool;
			editor.setAttribute("data-alloc-visible", isAllocVisible);
		}
		const toggleFurnitureHandler = (bool)=>{
			isFurnitureVisible = bool;
			wall.settings.isFurnitureVisible = isFurnitureVisible;
			editor.setAttribute("data-furniture-visible", isFurnitureVisible);
			if(this.isEditor){
				save();	
			}
		}
		const initToggleVisibleButtonEvents = ()=>{
			toggleScaleBtn.addEventListener("click", ()=>{
				toggleScaleHandler(!isScaleVisible);
			}, false);
			toggleAllocBtn.addEventListener("click", ()=>{
				toggleAllocHandler(!isAllocVisible)
			}, false);
			toggleFurnitureBtn.addEventListener("click", ()=>{
				toggleFurnitureHandler(!isFurnitureVisible)
			}, false);
		}
		if(toggleFurnitureBtn) toggleFurnitureBtn.checked = isFurnitureVisible;
		editor.setAttribute("data-furniture-visible", isFurnitureVisible);
		editor.setAttribute("data-alloc-visible", isAllocVisible);


		/*
			editor全体
			ドラッグアンドドロップで移動
		*/
		let isDragging = false;
		let isEditorDragMoved = false;
		let dragLastX = 0;
		let dragLastY = 0;
		let dragStartX = 0;
		let dragStartY = 0;
		const dragstart = (e)=>{
			if(!isDragging){
				editor.addEventListener("mousemove", dragmove, false);
				editor.addEventListener("mouseup", dragend, false);
				editor.addEventListener("mouseleave", dragend, false);
				dragLastX = e.screenX;
				dragLastY = e.screenY;
				dragStartX = e.screenX;
				dragStartY = e.screenY;
				isDragging = true;
				isEditorDragMoved = false;
			}
		};
		const dragmove = (e)=>{
			if(!isDragging) return;
			ctrl.editor.moveOffsetX += (e.screenX - dragLastX);
			ctrl.editor.moveOffsetY += (e.screenY - dragLastY);
			dragLastX = e.screenX;
			dragLastY = e.screenY;
			root.style.setProperty('--moveOffsetX',ctrl.editor.moveOffsetX+"px");
			root.style.setProperty('--moveOffsetY',ctrl.editor.moveOffsetY+"px");
			editor.classList.add("dragging");
			if( Math.sqrt(Math.pow(e.screenX-dragStartX, 2) + Math.pow(e.screenY-dragStartY, 2)) > 1 ){
				isEditorDragMoved = true;
			}
		};
		const dragend = ()=>{
			if(isDragging){
				editor.removeEventListener("mousemove", dragmove);
				editor.removeEventListener("mouseup", dragend);
				editor.removeEventListener("mouseleave", dragend);
				isDragging = false;
				editor.classList.remove("dragging");
			}
		};
		const initEditorDragMoveEvents = ()=>{
			editor.addEventListener("mousedown", dragstart, false);
		}



		/*
			zoom制御
			0:  zoom (-5) - 20%;
			1:  zoom (-4) - 25%;
			2:  zoom (-3) - 33.33%;
			3:  zoom (-2) - 50%;
			4:  zoom (-1) - 66.67%;
			5:  zoom (0)  - 100%;
			6:  zoom (1)  - 150%;
			7:  zoom (2)  - 200%;
			8:  zoom (3)  - 300%;
			9:  zoom (4)  - 400%;
			10: zoom (5)  - 500%;
		*/
		//const zoomTable = [0.2, 0.25, 0.3333, 0.5, 0.6667, 1, 1.5, 2, 3, 4, 5];

		const zoomTable = [0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1,1.5,2,2.5,3,3.5,4,4.5,5,5.5,6,6.5,7,7.5,8,8.5,9,9.5,10]
		const zoomPlusBtn = document.querySelector("#ctrl_zoom_plus");
		const zoomMinusBtn = document.querySelector("#ctrl_zoom_minus");
		const zoomNumber = document.querySelector("#ctrl_zoom_num");
		const changeZoom = (isZoomUp)=>{
			const lastZoom = ctrl.editor.zoom;
			if(isZoomUp){
				ctrl.editor.zoom = Math.min(zoomTable.length-1, ctrl.editor.zoom+1);
			}else{
				ctrl.editor.zoom = Math.max(0, ctrl.editor.zoom-1);
			}
			const zoomDef = zoomTable[ctrl.editor.zoom]/zoomTable[lastZoom];
			ctrl.editor.moveOffsetX = ctrl.editor.moveOffsetX*(zoomDef);
			ctrl.editor.moveOffsetY = ctrl.editor.moveOffsetY*(zoomDef);
			root.style.setProperty('--moveOffsetX',ctrl.editor.moveOffsetX+"px");
			root.style.setProperty('--moveOffsetY',ctrl.editor.moveOffsetY+"px");
			setZoom();
		}
		const setZoom = ()=>{
			let number = (zoomTable[ctrl.editor.zoom]*100);
			if(number%1){
				zoomNumber.innerText = (number).toFixed(2)+"%";	
			}else{
				zoomNumber.innerText = number+"%";	
			}
			root.style.setProperty('--zoom',zoomTable[ctrl.editor.zoom]);
		}
		const initChangeZoomEvents = ()=>{
			setZoom();
			zoomPlusBtn.addEventListener("click", ()=>{ changeZoom(true) }, false);
			zoomMinusBtn.addEventListener("click", ()=>{ changeZoom(false) }, false);
		}




		/*
			canvasとinstance
			壁紙の拡大・移動
		*/
		let isInstanceActive = false;
		

		//背景塗りたし設定
		const applyFillColorSettings = ()=>{
			if(wall.wallpaper.fillcolor.left && wall.wallpaper.fillactive.left){
				root.style.setProperty('--wallpaperFillLeft', wall.wallpaper.fillcolor.left);
			}else{
				root.style.setProperty('--wallpaperFillLeft', "transparent");
			}
			if(wall.wallpaper.fillcolor.right && wall.wallpaper.fillactive.right){
				root.style.setProperty('--wallpaperFillRight', wall.wallpaper.fillcolor.right);
			}else{
				root.style.setProperty('--wallpaperFillRight', "transparent");
			}
			if(wall.wallpaper.fillcolor.top && wall.wallpaper.fillactive.top){
				root.style.setProperty('--wallpaperFillTop', wall.wallpaper.fillcolor.top);
			}else{
				root.style.setProperty('--wallpaperFillTop', "transparent");
			}
			if(wall.wallpaper.fillcolor.bottom && wall.wallpaper.fillactive.bottom){
				root.style.setProperty('--wallpaperFillBottom', wall.wallpaper.fillcolor.bottom);
			}else{
				root.style.setProperty('--wallpaperFillBottom', "transparent");
			}
			if(this.isEditor){
				setFactoryAutoinputText();
				save();	
			}
		}

		// 反転リピート設定
		const flipX = -1;
		const proposition = editor_root.querySelector(".canvas__fill__wallpaper__proposition");
		const inverse = editor_root.querySelector(".canvas__fill__wallpaper__inverse");
		const converse = editor_root.querySelector(".canvas__fill__wallpaper__converse");
		const contraposition = editor_root.querySelector(".canvas__fill__wallpaper__contraposition");
		const rcanvas = document.createElement("canvas");
		const rctx = rcanvas.getContext("2d");
		const sourceImg = document.createElement("img");
		const createFlippedPropositionImage = ()=>{
			rctx.clearRect(0, 0, rcanvas.width, rcanvas.width);
			rctx.transform(-2, 0, 0, 2, wall.wallpaper.dimension.natural_width*2, 0);
			rctx.drawImage(sourceImg, 0, 0, wall.wallpaper.dimension.natural_width, wall.wallpaper.dimension.natural_height);
			rctx.setTransform(1, 0, 0, 1, 0, 0);
			return rcanvas.toDataURL("image/png");
		}
		const createInverseImage = ()=>{
			rctx.clearRect(0, 0, rcanvas.width, rcanvas.width)
			rctx.scale(-1, 1)
			rctx.drawImage(sourceImg, wall.wallpaper.dimension.natural_width*-2, 0, wall.wallpaper.dimension.natural_width, wall.wallpaper.dimension.natural_height);
			rctx.setTransform(1, 0, 0, 1, 0, 0);
			return rcanvas.toDataURL("image/png");
		}
		const createConverseImage = ()=>{
			rctx.clearRect(0, 0, rcanvas.width, rcanvas.width)
			rctx.scale(1, -1)
			rctx.drawImage(sourceImg, 0, wall.wallpaper.dimension.natural_height*-2, wall.wallpaper.dimension.natural_width, wall.wallpaper.dimension.natural_height);
			rctx.setTransform(1, 0, 0, 1, 0, 0);
			return rcanvas.toDataURL("image/png");
		}
		const createContrapositionImage = ()=>{
			rctx.clearRect(0, 0, rcanvas.width, rcanvas.width)
			rctx.scale(-1, -1)
			rctx.drawImage(sourceImg, wall.wallpaper.dimension.natural_width*-2, wall.wallpaper.dimension.natural_height*-2, wall.wallpaper.dimension.natural_width, wall.wallpaper.dimension.natural_height);
			rctx.setTransform(1, 0, 0, 1, 0, 0);
			return rcanvas.toDataURL("image/png");
		}
		const applyRepeatSettings = ()=>{
			rcanvas.width = wall.wallpaper.dimension.natural_width*2;
			rcanvas.height = wall.wallpaper.dimension.natural_height*2;
			/*
			if(wall.wallpaper.reverseRepeat){
				proposition.style.backgroundImage = "url("+createFlippedPropositionImage()+")";	
				ghostDesign.style.transform = "scaleX(-1)";
			}else{
				proposition.style.backgroundImage = null;
				ghostDesign.style.transform = null;
			}
			*/
			if(wall.wallpaper.repeat == "horizontal" && wall.wallpaper.reverseRepeat){
				inverse.style.backgroundImage = "url("+createInverseImage()+")";
				converse.style.backgroundImage = null;
				contraposition.style.backgroundImage = null;
			}else if(wall.wallpaper.repeat == "vertical" && wall.wallpaper.reverseRepeat){
				inverse.style.backgroundImage = null;
				converse.style.backgroundImage = "url("+createConverseImage()+")";
				contraposition.style.backgroundImage = null;
			}else if(wall.wallpaper.repeat == "repeat" && wall.wallpaper.reverseRepeat){
				inverse.style.backgroundImage = "url("+createInverseImage()+")";
				converse.style.backgroundImage = "url("+createConverseImage()+")";
				contraposition.style.backgroundImage = "url("+createContrapositionImage()+")";
			}else{
				inverse.style.backgroundImage = null;
				converse.style.backgroundImage = null;
				contraposition.style.backgroundImage = null;
			}
			if(wall.wallpaper.repeat == "horizontal"){
				root.style.setProperty('--wallpaperRepeat', "repeat-x");
			}else if(wall.wallpaper.repeat == "vertical"){
				root.style.setProperty('--wallpaperRepeat', "repeat-y");
			}else if(wall.wallpaper.repeat == "repeat"){
				root.style.setProperty('--wallpaperRepeat', "repeat");
			}else{
				root.style.setProperty('--wallpaperRepeat', "no-repeat");
			}
			/*
			if(editor.classList.contains("editor_repaint")){
				setTimeout(()=>{
					editor.classList.remove("editor_repaint")
				}, 500);
			}
			*/
		}
		const initRepeatSetting = ()=>{
			sourceImg.onload = applyRepeatSettings;
			sourceImg.crossOrigin = "anonymous"; 
			sourceImg.src = wall.wallpaper.imgPath+"?type=original";
		}
		

		let is_shift_pressed = false;
		let is_alt_pressed = false;

		const param_coordinate_x = document.querySelector(".param_coordinate_x span");
		const param_coordinate_y = document.querySelector(".param_coordinate_y span");
		const param_dimension_w = document.querySelector(".param_dimension_w span");
		const param_dimension_h = document.querySelector(".param_dimension_h span");
		const param_scale_h = document.querySelector(".param_scale_h span");
		const param_scale_v = document.querySelector(".param_scale_v span");
		const param_aspect_ratio = document.querySelector(".param_aspect_ratio span");

		const update_params = ()=>{
			containDimension(wall.wallpaper.dimension.width, wall.wallpaper.dimension.height, true);
			wall.wallpaper.position.x = Math.round(wall.wallpaper.position.x);
			wall.wallpaper.position.y = Math.round(wall.wallpaper.position.y);
			root.style.setProperty('--wallpaperWidth', wall.wallpaper.dimension.width+"px");
			root.style.setProperty('--wallpaperHeight', wall.wallpaper.dimension.height+"px");
			root.style.setProperty('--wallpaperAspectRatio', wall.wallpaper.dimension.width/wall.wallpaper.dimension.height);
			root.style.setProperty('--wallpaperCoordinateX', wall.wallpaper.position.x+"px");
			root.style.setProperty('--wallpaperCoordinateY', wall.wallpaper.position.y+"px");
			/*
			param_coordinate_x.innerText = wall.wallpaper.position.x+"px";
			param_coordinate_y.innerText = wall.wallpaper.position.y+"px";
			param_dimension_w.innerText = wall.wallpaper.dimension.width+"px";
			param_dimension_h.innerText = wall.wallpaper.dimension.height+"px";
			param_scale_h.innerText =  (wall.wallpaper.dimension.width/wall.wallpaper.dimension.natural_width).toFixed(4);
			param_scale_v.innerText =  (wall.wallpaper.dimension.height/wall.wallpaper.dimension.natural_height).toFixed(4);
			param_aspect_ratio.innerText = (wall.wallpaper.dimension.width/wall.wallpaper.dimension.height).toFixed(4);
			*/
			if(this.isEditor){
				validateErrors();
				setFactoryAutoinputText();
				save();	
			}
		}


		//拡大縮小ドラッグ
		const grips = editor_root.querySelectorAll(".instance__grip");
		const initGripDrag = (grip)=>{
			const grip_v = grip.dataset.gripV;
			const grip_h = grip.dataset.gripH;
			let isGripDragging = false;
			let gripLastX = 0;
			let gripLastY = 0;
			let gripStartX = 0;
			let gripStartY = 0;
			let wpStartWidth = wall.wallpaper.dimension.width;
			let wpStartHeight = wall.wallpaper.dimension.height;
			let wpStartCoordX = wall.wallpaper.position.x;
			let wpStartCoordY = wall.wallpaper.position.y;
			const gripstart = (e)=>{
				if(!isGripDragging){
					editor.addEventListener("mousemove", gripmove, false);
					editor.addEventListener("mouseup", gripend, false);
					editor.addEventListener("mouseleave", gripend, false);
					gripLastX = e.screenX;
					gripLastY = e.screenY;
					gripStartX = e.screenX;
					gripStartY = e.screenY;
					wpStartWidth = wall.wallpaper.dimension.width;
					wpStartHeight = wall.wallpaper.dimension.height;
					wpStartCoordX = wall.wallpaper.position.x;
					wpStartCoordY = wall.wallpaper.position.y;
					isGripDragging = true;
					e.stopPropagation();
					e.preventDefault();
					window.addEventListener("keydown", grippingKeyPressHandler, false);
					window.addEventListener("keyup", grippingKeyPressHandler, false);
					editor.setAttribute("data-cursor", "grip-"+grip_v+"-"+grip_h);
				}
				return false;
			};
			const gripmove = (e)=>{
				if(!isGripDragging) return;
				dragScaleChange(e);
			};
			const gripend = (e)=>{
				if(isGripDragging){
					dragScaleStick(e);
					editor.removeEventListener("mousemove", gripmove);
					editor.removeEventListener("mouseup", gripend);
					editor.removeEventListener("mouseleave", gripend);
					isGripDragging = false;
					window.removeEventListener("keydown", grippingKeyPressHandler);
					window.removeEventListener("keyup", grippingKeyPressHandler);
					editor.removeAttribute("data-cursor");
				}
			};
			const dragScaleChange = (e)=>{
				const screenX = e ? e.screenX : gripLastX;
				const screenY = e ? e.screenY : gripLastY;
				const magnitudeX = gripStartX - screenX;
				const magnitudeY = gripStartY - screenY;
				const alt_param = is_alt_pressed ? 2 : 1;
				if(!wall.wallpaper.allow_variable_scale || is_shift_pressed){
					//等倍拡大
					let width_h,height_h,height_v,width_v;
					if(grip_v == "top"){
						height_v = wpStartHeight + (magnitudeY/zoomTable[ctrl.editor.zoom]/window.settings.grandscale)*alt_param;
						width_v = height_v * (wall.wallpaper.dimension.natural_width/wall.wallpaper.dimension.natural_height);
					}else{
						height_v = wpStartHeight - (magnitudeY/zoomTable[ctrl.editor.zoom]/window.settings.grandscale)*alt_param;
						width_v = height_v * (wall.wallpaper.dimension.natural_width/wall.wallpaper.dimension.natural_height);
					}
					if(grip_h == "left"){
						width_h = wpStartWidth + (magnitudeX/zoomTable[ctrl.editor.zoom]/window.settings.grandscale)*alt_param;
						height_h = width_h * (wall.wallpaper.dimension.natural_height/wall.wallpaper.dimension.natural_width);
					}else{
						width_h = wpStartWidth - (magnitudeX/zoomTable[ctrl.editor.zoom]/window.settings.grandscale)*alt_param;
						height_h = width_h * (wall.wallpaper.dimension.natural_height/wall.wallpaper.dimension.natural_width);
					}
					if(width_h > width_v){
						wall.wallpaper.dimension.width = width_v;
						wall.wallpaper.dimension.height = height_v;
					}else{
						wall.wallpaper.dimension.width = width_h;
						wall.wallpaper.dimension.height = height_h;
					}
					if(grip_v == "middle"){
						wall.wallpaper.dimension.width = width_h;
						wall.wallpaper.dimension.height = height_h;
					}
					if(grip_h == "middle"){
						wall.wallpaper.dimension.width = width_v;
						wall.wallpaper.dimension.height = height_v;
					}
				}else{
					//変倍拡大
					if(grip_v == "top"){
						wall.wallpaper.dimension.height = wpStartHeight + (magnitudeY/zoomTable[ctrl.editor.zoom]/window.settings.grandscale)*alt_param;
					}else if(grip_v=="bottom"){
						wall.wallpaper.dimension.height = wpStartHeight - (magnitudeY/zoomTable[ctrl.editor.zoom]/window.settings.grandscale)*alt_param;
					}
					if(grip_h == "right"){
						wall.wallpaper.dimension.width = wpStartWidth - (magnitudeX/zoomTable[ctrl.editor.zoom]/window.settings.grandscale)*alt_param;
					}else if(grip_h == "left"){
						wall.wallpaper.dimension.width = wpStartWidth + (magnitudeX/zoomTable[ctrl.editor.zoom]/window.settings.grandscale)*alt_param;
					}
				}
				containDimension(wall.wallpaper.dimension.width, wall.wallpaper.dimension.height, true);

				//座標算出
				if(grip_v == "top"){
					wall.wallpaper.position.y = wpStartCoordY + (wpStartHeight - wall.wallpaper.dimension.height)/alt_param;
				}else if(grip_v == "middle"){
					if(!wall.wallpaper.allow_variable_scale || is_shift_pressed){
						wall.wallpaper.position.y = wpStartCoordY + (wpStartHeight - wall.wallpaper.dimension.height)/2;
					}else{
						wall.wallpaper.position.y = wpStartCoordY;
					}
				}else if(grip_v=="bottom"){
					wall.wallpaper.position.y = is_alt_pressed ? (wpStartCoordY + (wpStartHeight - wall.wallpaper.dimension.height)/alt_param) : wpStartCoordY;
				}
				if(grip_h == "right"){
					wall.wallpaper.position.x = is_alt_pressed ? (wpStartCoordX + (wpStartWidth - wall.wallpaper.dimension.width)/alt_param) : wpStartCoordX;
				}else if(grip_h == "middle"){
					if(!wall.wallpaper.allow_variable_scale || is_shift_pressed){
						wall.wallpaper.position.x = wpStartCoordX + (wpStartWidth - wall.wallpaper.dimension.width)/2;
					}else{
						wall.wallpaper.position.x = wpStartCoordX;
					}
				}else if(grip_h == "left"){
					wall.wallpaper.position.x = wpStartCoordX + (wpStartWidth - wall.wallpaper.dimension.width)/alt_param;
				}

				gripLastX = screenX;
				gripLastY = screenY;
				update_params();
				layoutEditorUpdatedHandler();
			}
			const dragScaleStick = (e)=>{
				const alt_param = is_alt_pressed ? 2 : 1;
				const scaleStickThreshold = 20;
				if(grip_v == "top"){
					if(Math.abs(wall.wallpaper.position.y) <= scaleStickThreshold){
						wall.wallpaper.dimension.height = wall.wallpaper.dimension.height + wall.wallpaper.position.y*alt_param;
						if(!wall.wallpaper.allow_variable_scale || is_shift_pressed){
							//等倍処理
							if(grip_h == "middle"){
								wall.wallpaper.position.x += (wall.wallpaper.dimension.width - (wall.wallpaper.dimension.height * (wall.wallpaper.dimension.natural_width/wall.wallpaper.dimension.natural_height)))/2;
							}
							wall.wallpaper.dimension.width = wall.wallpaper.dimension.height * (wall.wallpaper.dimension.natural_width/wall.wallpaper.dimension.natural_height);
							containDimension(wall.wallpaper.dimension.width, wall.wallpaper.dimension.height, true);
						}
						wall.wallpaper.position.y = 0;
					}
				}else if(grip_v=="bottom"){
					if(Math.abs((this.alloc_height - wall.wallpaper.dimension.height) - wall.wallpaper.position.y) <= scaleStickThreshold){
						wall.wallpaper.dimension.height = wall.wallpaper.dimension.height + ((this.alloc_height - wall.wallpaper.dimension.height) - wall.wallpaper.position.y)*alt_param;
						if(!wall.wallpaper.allow_variable_scale || is_shift_pressed){
							//等倍処理
							if(grip_h == "middle"){
								wall.wallpaper.position.x += (wall.wallpaper.dimension.width - wall.wallpaper.dimension.height * (wall.wallpaper.dimension.natural_width/wall.wallpaper.dimension.natural_height))/2;
							}
							wall.wallpaper.dimension.width = wall.wallpaper.dimension.height * (wall.wallpaper.dimension.natural_width/wall.wallpaper.dimension.natural_height);
							containDimension(wall.wallpaper.dimension.width, wall.wallpaper.dimension.height, true);
						}
						wall.wallpaper.position.y = this.alloc_height - wall.wallpaper.dimension.height;
					}
				}
				if(grip_h == "left"){
					if(Math.abs(wall.wallpaper.position.x) <= scaleStickThreshold){
						wall.wallpaper.dimension.width = wall.wallpaper.dimension.width + wall.wallpaper.position.x*alt_param;
						if(!wall.wallpaper.allow_variable_scale || is_shift_pressed){
							//等倍処理
							if(grip_v == "middle"){
								wall.wallpaper.position.y += (wall.wallpaper.dimension.height - wall.wallpaper.dimension.width * (wall.wallpaper.dimension.natural_height/wall.wallpaper.dimension.natural_width))/2;
							}
							wall.wallpaper.dimension.height = wall.wallpaper.dimension.width * (wall.wallpaper.dimension.natural_height/wall.wallpaper.dimension.natural_width);
							containDimension(wall.wallpaper.dimension.width, wall.wallpaper.dimension.height, true);
						}
						wall.wallpaper.position.x = 0;
					}
				}else if(grip_h == "right"){
					if(Math.abs((this.alloc_width - wall.wallpaper.dimension.width) - wall.wallpaper.position.x) <= scaleStickThreshold){
						wall.wallpaper.dimension.width = wall.wallpaper.dimension.width + ((this.alloc_width - wall.wallpaper.dimension.width) - wall.wallpaper.position.x)*alt_param;
						if(!wall.wallpaper.allow_variable_scale || is_shift_pressed){
							//等倍処理
							if(grip_v == "middle"){
								wall.wallpaper.position.y += (wall.wallpaper.dimension.height - wall.wallpaper.dimension.width * (wall.wallpaper.dimension.natural_height/wall.wallpaper.dimension.natural_width))/2;
							}
							wall.wallpaper.dimension.height = wall.wallpaper.dimension.width * (wall.wallpaper.dimension.natural_height/wall.wallpaper.dimension.natural_width);
							containDimension(wall.wallpaper.dimension.width, wall.wallpaper.dimension.height, true);
						}
						wall.wallpaper.position.x = this.alloc_width - wall.wallpaper.dimension.width;
					}
				}
				update_params();
				layoutEditorUpdatedHandler();
			}
			const grippingKeyPressHandler = (e)=>{
				if(e.key=="Shift" || e.key=="Alt"){
					if(isGripDragging) requestAnimationFrame(()=>{gripmove(null)})
				}
			}
			grip.addEventListener("mousedown", gripstart, false);
		}
		const initGrips = ()=>{
			grips.forEach(grip=>initGripDrag(grip));
		}
		

		//移動ドラッグ
		const instanceBound = editor_root.querySelector(".instance__bound");
		let isWpDragging = false;
		let wpDragLastX = 0;
		let wpDragLastY = 0;
		let dragDirectionV = null;	//上:true , 下:false
		let dragDirectionH = null;	//左:true , 右:false
		const moveStickThreshold = 20;
		const wpDragstart = (e)=>{
			if(!isWpDragging){
				editor.addEventListener("mousemove", wpDragmove, false);
				editor.addEventListener("mouseup", wpDragend, false);
				editor.addEventListener("mouseleave", wpDragend, false);
				wpDragLastX = e.screenX;
				wpDragLastY = e.screenY;
				isWpDragging = true;
				e.stopPropagation();
				e.preventDefault();
				if(!isInstanceActive) toggleInstanceActive();
			}
			return false;
		};
		const wpDragmove = (e)=>{
			if(!isWpDragging) return;
			const moveX = (e.screenX - wpDragLastX)/zoomTable[ctrl.editor.zoom];
			const moveY = (e.screenY - wpDragLastY)/zoomTable[ctrl.editor.zoom];
			if(wpDragLastX > e.screenX) dragDirectionH = true;	//左
			if(wpDragLastX < e.screenX) dragDirectionH = false;	//右
			if(wpDragLastY > e.screenY) dragDirectionV = true;	//上
			if(wpDragLastY < e.screenY) dragDirectionV = false;	//下

			wpDragLastX = e.screenX;
			wpDragLastY = e.screenY;
			wall.wallpaper.position.x += moveX/window.settings.grandscale;
			wall.wallpaper.position.y += moveY/window.settings.grandscale;
			update_params();
			layoutEditorUpdatedHandler();
		};
		const wpDragend = ()=>{
			if(isWpDragging){
				editor.removeEventListener("mousemove", wpDragmove);
				editor.removeEventListener("mouseup", wpDragend);
				editor.removeEventListener("mouseleave", wpDragend);
				dragMovedStick();
				isWpDragging = false;
			}
		};
		const initInstanceDragMoveEvent = ()=>{
			instanceBound.addEventListener("mousedown", wpDragstart, false);	
		}
		const dragMovedStick = ()=>{
			const stickToLeft = Math.abs(wall.wallpaper.position.x) <= moveStickThreshold;
			const stickToTop = Math.abs(wall.wallpaper.position.y) <= moveStickThreshold;
			const stickToRight = Math.abs((this.alloc_width - wall.wallpaper.dimension.width) - wall.wallpaper.position.x) <= moveStickThreshold;
			const stickToBottom = Math.abs((this.alloc_height - wall.wallpaper.dimension.height) - wall.wallpaper.position.y) <= moveStickThreshold;

			if(dragDirectionH){
				//左方向へのドラッグ後なら、左辺への吸着を優先
				if(stickToRight){ wall.wallpaper.position.x = this.alloc_width - wall.wallpaper.dimension.width; }
				if(stickToLeft){ wall.wallpaper.position.x = 0; }
			}else{
				//右方向へのドラッグ後なら、右辺への吸着を優先
				if(stickToLeft){ wall.wallpaper.position.x = 0; }
				if(stickToRight){ wall.wallpaper.position.x = this.alloc_width - wall.wallpaper.dimension.width; }
			}

			if(dragDirectionV){
				//上方向へのドラッグ後なら、上辺への吸着を優先
				if(stickToBottom){ wall.wallpaper.position.y = this.alloc_height - wall.wallpaper.dimension.height; }
				if(stickToTop){ wall.wallpaper.position.y = 0; }
			}else{
				//下方向へのドラッグ後なら、下辺への吸着を優先
				if(stickToTop){ wall.wallpaper.position.y = 0; }
				if(stickToBottom){ wall.wallpaper.position.y = this.alloc_height - wall.wallpaper.dimension.height; }
			}
				
			update_params();
			layoutEditorUpdatedHandler();
		}
		

		const instanceOutFocusCatcher = editor_root.querySelector(".instance__out-focus-catcher");
		const toggleInstanceActive = ()=>{
			if(isInstanceActive){
				if(!isEditorDragMoved){
				isInstanceActive = false;
					editor.classList.remove("active");
					instanceBound.addEventListener("click", toggleInstanceActive, false);
					instanceOutFocusCatcher.removeEventListener("click", toggleInstanceActive);
				}
			}else{
				isInstanceActive = true;
				editor.classList.add("active");
				instanceBound.removeEventListener("click", toggleInstanceActive);
				instanceOutFocusCatcher.addEventListener("click", toggleInstanceActive, false);
			}
		}
		const initToggleInstanceActiveEvent = ()=>{
			instanceBound.addEventListener("click", toggleInstanceActive, false);
		}


		const wpMove = (direction)=>{
			const power = is_shift_pressed ? 10 : 1;
			switch(direction){
				case "up":
					wall.wallpaper.position.y = wall.wallpaper.position.y-power;
					break;
				case "right":
					wall.wallpaper.position.x = wall.wallpaper.position.x+power;
					break;
				case "down":
					wall.wallpaper.position.y = wall.wallpaper.position.y+power;
					break;
				case "left":
					wall.wallpaper.position.x = wall.wallpaper.position.x-power;
					break;
			}
			update_params();
			layoutEditorUpdatedHandler();
		}
		const keyDownHandler = (e)=>{
			if(ctrl.isSettingPanelOpen) return;
			if(e.key=="Shift"){
				if(!is_shift_pressed) is_shift_pressed = true;
			}
			if(e.key=="Alt"){
				if(!is_alt_pressed) is_alt_pressed = true;
			}
			if(e.key=="ArrowUp"){
				editor.classList.add("moveByKey");
				wpMove("up");
			}
			if(e.key=="ArrowRight"){
				editor.classList.add("moveByKey");
				wpMove("right");
			}
			if(e.key=="ArrowDown"){
				editor.classList.add("moveByKey");
				wpMove("down");
			}
			if(e.key=="ArrowLeft"){
				editor.classList.add("moveByKey");
				wpMove("left");
			}
		}
		const keyUpHandler = (e)=>{
			if(ctrl.isSettingPanelOpen) return;
			if(e.key=="Shift"){
				if(is_shift_pressed) is_shift_pressed = false;
			}
			if(e.key=="Alt"){
				if(is_alt_pressed) is_alt_pressed = false;
			}
			editor.classList.remove("moveByKey");
		}
		const initKeyMoveEvents = ()=>{
			window.addEventListener("keydown", keyDownHandler, false);
			window.addEventListener("keyup", keyUpHandler, false);
		}


		/*
			scale, furniture, panel
			壁面の幅と高さのスケール
			入隅・出隅
			什器・建具
			パネル
		*/
		const calc_padding_bottom = ()=>{
			//上余白を50mm固定、下余白は100mm毎増減するロール高さの端数（最低50mm）
			const padding_top = 50;
			let pb = Math.ceil((wall.dimension.height+padding_top*2)/100)*100 - (wall.dimension.height+padding_top);
			if(pb == 0) pb = 100;
			return pb;

			/*
			//（パネル工法の場合は上下ともに50mmにする場合）
			if(wall.settings.material == "interior_sheet" && wall.settings.interiorsheet_method == "panel"){
				return 50;
			}else{
				return Math.ceil((wall.dimension.height+padding_top)/100)*100 - (wall.dimension.height+padding_top);
			}
			*/
		}
		const calc_padding_horizontal = ()=>{
			if(wall.settings.material == "interior_sheet" && wall.settings.interiorsheet_method == "panel"){
				return wall.dimension.padding_horizontal;
			}else{
				return 50;
			}
		}
		const setScaleFurniturePanel = ()=>{
			root.style.setProperty('--wallWidth',wall.dimension.width+"px");
			root.style.setProperty('--wallHeight',wall.dimension.height+"px");
			root.style.setProperty('--wallpaperImagePath', "url("+wall.wallpaper.imgPath+")");
			root.style.setProperty('--wallpaperWidth', wall.wallpaper.dimension.width+"px");
			root.style.setProperty('--wallpaperHeight', wall.wallpaper.dimension.height+"px");
			root.style.setProperty('--wallpaperAspectRatio', wall.wallpaper.dimension.width/wall.wallpaper.dimension.height);
			root.style.setProperty('--wallpaperCoordinateX', wall.wallpaper.position.x+"px");
			root.style.setProperty('--wallpaperCoordinateY', wall.wallpaper.position.y+"px");

			let padding_top = 50;
			let padding_bottom = calc_padding_bottom();
			let padding_horizontal = calc_padding_horizontal();
			if(wall.settings.material == "wallpanel"){
				padding_top = padding_bottom = padding_horizontal = 150;
			}
			root.style.setProperty('--paddingTop', padding_top+"px");
			root.style.setProperty('--paddingBottom', padding_bottom+"px");
			root.style.setProperty('--paddingHorizontal', padding_horizontal+"px");
			

			//壁面寸法
			const param_dimension_value = editor_root.querySelector(".param__dimension-value");
			const scale_vertical = editor_root.querySelector(".scale_vertical");
			const scale_vertical_label = editor_root.querySelector(".scale_vertical__full_height__label");
			scale_vertical_label.innerText = wall.dimension.height;
			const scale_horizontal = editor_root.querySelector(".scale_horizontal");
			const scale_fullwidth_label = editor_root.querySelector(".scale_horizontal__full_width__label");
			scale_fullwidth_label.innerText = wall.dimension.width;
			let scale_horizontal_segments = [];
			if(param_dimension_value) param_dimension_value.innerText = "W"+wall.dimension.width+"mm × H"+wall.dimension.height+"mm";

			//パネル敷設
			let wall_panels = null;
			const frame = editor_root.querySelector(".frame");
			frame.innerHTML = "";
			if(wall.settings.material == "interior_sheet" && wall.settings.interiorsheet_method == "panel"){
				if(wall.panels){
					if(Array.isArray(wall.panels.width)){ if(wall.panels.width.length > 0){ // panels.widthに幅配列が入っていたら
						if(wall.panels.width.reduce((acc, current) => acc + current) == wall.dimension.width){ // panels.widthの合計が壁面幅と一致したら
							const pannels = (wall.panels.origin=="left") ? wall.panels.width : wall.panels.width.toReversed();
							pannels.forEach((panel_width,i)=>{
								if(panel_width <= 0) return;
								const panel = document.createElement("div");
								const position = pannels.reduce((acc,current,index)=>{
									return (index<=i-1) ? acc+current : acc;
								}, 0);
								panel.classList.add("frame__panel");
								panel.style.width = "calc(calc("+panel_width+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+") + 1px)";
								panel.style.left = "calc(calc("+position+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+") - 0px)";
								frame.append(panel);
								scale_horizontal_segments.push(pannels.reduce((acc,current,index)=>{
									return (index<=i) ? acc+current : acc;
								}, 0));
							});
						}
					}}
				}
			}

			//入隅・出隅
			const existing_scale_corners = scale_horizontal.querySelector(".scale_horizontal__corner");
			if(existing_scale_corners) existing_scale_corners.remove();
			if(wall.settings.material == "wallpaper" || (wall.settings.material == "interior_sheet" && wall.settings.interiorsheet_method == "normal")){
				if(Array.isArray(wall.corner)){ if(wall.corner.length > 0){
					const scale_corners = document.createElement("div");
					scale_corners.classList.add("scale_horizontal__corner");
					let corners = [];

					//入隅・出隅リストを整形。はみ出していたり、重複している項目を取り除く
					wall.corner.forEach(corner => {
						let pos = corner.position*1;
						if(pos === 0 || pos === wall.dimension.width) return;
						if(corner.origin == "right") pos = wall.dimension.width - corner.position;
						if(pos < 0 || wall.dimension.width < pos) return;
						let isUnique = true;
						corners.forEach(c=>{
							isUnique = isUnique && (c.left_pos != pos);
						});
						if(isUnique){
							const c = JSON.parse(JSON.stringify(corner));
							c.left_pos = pos;
							corners.push(c);
							if(!scale_horizontal_segments.includes(pos)) scale_horizontal_segments.push(pos);
						}
					});

					if(corners.length > 0){
						//左基点位置の順でソート
						corners.sort((a,b)=>{
							if(a.left_pos < b.left_pos){
								return -1;
							}else if(a.left_pos > b.left_pos){
								return 1;
							}else{
								return 0;
							}
						});

						let last_pos = 0;
						const appendCornerDom = (pos,type)=>{
							const corner = document.createElement("div");
							const corner_label = document.createElement("div");
							corner.classList.add("scale_horizontal__corner__line");
							corner_label.classList.add("scale_horizontal__corner__label");
							corner_label.innerText = (type=="inner") ? "入隅" : "出隅";
							corner.style.left = "calc("+pos+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+")";
							corner.append(corner_label);
							scale_corners.append(corner);
						}
						corners.forEach(corner=>{
							const length = corner.left_pos - last_pos;
							appendCornerDom(corner.left_pos, corner.type);
							last_pos = corner.left_pos;
						});
						scale_horizontal.append(scale_corners);
					}
				}};
			}

			//水平幅分寸法
			const existing_scale_partial_width = scale_horizontal.querySelector(".scale_horizontal__partial_width");
			if(existing_scale_partial_width) existing_scale_partial_width.remove();
			if(Array.isArray(scale_horizontal_segments)){ if(scale_horizontal_segments.length > 0){
				const scale_partial_width = document.createElement("div");
				scale_partial_width.classList.add("scale_horizontal__partial_width");
				scale_horizontal_segments.sort((a,b)=>{
					return a-b;
				});
				const appendPartialScaleDom = (width,position)=>{
					const partial_scale = document.createElement("div");
					const partial_scale_label = document.createElement("div");
					partial_scale.classList.add("scale_horizontal__partial_width__partial");
					partial_scale_label.classList.add("scale_horizontal__partial_width__label");
					partial_scale_label.innerText = width;
					partial_scale.style.width = "calc(calc("+width+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+") + 1px)";
					partial_scale.style.left = "calc(calc("+position+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+") - 0px)";
					partial_scale.append(partial_scale_label);
					scale_partial_width.append(partial_scale);
				}
				let last_pos = 0;
				scale_horizontal_segments.forEach(segment=>{
					const length = segment - last_pos;
					appendPartialScaleDom(length, last_pos);
					last_pos = segment;
				});
				if(last_pos < wall.dimension.width){
					appendPartialScaleDom(wall.dimension.width - last_pos, last_pos);
				}
				scale_horizontal.append(scale_partial_width);
			}}

			//割り付け寸法
			const circle_num = ["①","②","③","④","⑤","⑥","⑦","⑧","⑨","⑩","⑪","⑫","⑬","⑭","⑮","⑯","⑰","⑱","⑲","⑳"];
			const alloc_rolls = editor_root.querySelector(".allocate__rolls");
			const alloc_scale_h = editor_root.querySelector(".allocate__scale_horizontal");
			const alloc_scale_h_total = editor_root.querySelector(".allocate__scale_horizontal__full_width");
			const alloc_scale_h_partial = editor_root.querySelector(".allocate__scale_horizontal__partial_width");
			const alloc_scale_v = editor_root.querySelector(".allocate__scale_vertical__full_height__label");
			const param_alloc_value = editor_root.querySelector(".param__allocation-value");
			alloc_scale_v.innerText = wall.dimension.height+(padding_top+padding_bottom);
			this.alloc_height = wall.dimension.height+(padding_top+padding_bottom);
			this.alloc_width = wall.dimension.width + padding_horizontal*2;
			this.is_alloc_panel = false;
			alloc_rolls.innerHTML = "";
			alloc_scale_h_partial.innerHTML = "";
			if(alloc_scale_h_total) alloc_scale_h_total.remove();
			if(wall.settings.material == "interior_sheet" && wall.settings.interiorsheet_method == "panel"){
				if(wall.panels){
					if(Array.isArray(wall.panels.width)){ if(wall.panels.width.length > 0){ // panels.widthに幅配列が入っていたら
						let widthArray = (wall.panels.origin=="right") ? wall.panels.width.toReversed() : wall.panels.width;
						if(widthArray.reduce((acc, current) => acc + current) == wall.dimension.width){ // panels.widthの合計が壁面幅と一致したら
							root.style.setProperty('--allocWidth',(wall.dimension.width + padding_horizontal*2)+"px");
							let panel_position = 0;
							this.alloc_rolls = [];
							for(let i=0; i<widthArray.length; i++){
								const roll = document.createElement("div");
								const panel_roll_width = widthArray[i] + padding_horizontal*2;
								roll.classList.add("allocate__rolls__roll");
								roll.style.width = "calc(calc("+panel_roll_width+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+") + "+(i==widthArray.length-1 ? 2 : 3)+"px)";
								roll.style.left = "calc(calc("+(panel_position)+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+") - 0px)";
								alloc_rolls.append(roll);

								const partial = document.createElement("div");
								const number = document.createElement("div");
								const size = document.createElement("div");
								number.classList.add("number");
								let label = "";
								if(wall.allocation.origin == "left"){
									//label = (20>i) ? circle_num[i] : (i+1);
									label = "分割"+(i+1);
								}else{
									//const indx = (widthArray.length-i-1);
									//label = (20>indx) ? circle_num[indx] : (indx+1);
									label = "分割"+(widthArray.length-i);
								}
								number.innerHTML = label;
								this.alloc_rolls.push({
									label:label,
									width: panel_roll_width
								});
								size.classList.add("size");
								size.innerText = panel_roll_width;
								partial.append(number);
								partial.append(size);
								partial.style.width = "calc(calc("+panel_roll_width+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+") + 1px)";
								partial.style.left = "calc("+(panel_position)+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+")";
								partial.classList.add("allocate__scale_horizontal__partial_width__partial");
								alloc_scale_h_partial.append(partial);
								panel_position += widthArray[i];
							}
							const total = document.createElement("div");
							const total_label = document.createElement("div");
							total.classList.add("allocate__scale_horizontal__full_width");
							total_label.classList.add("allocate__scale_horizontal__full_width__label");
							total_label.innerText = wall.dimension.width + padding_horizontal*2;
							total.append(total_label);
							alloc_scale_h.append(total);

							let param_textsdata = [];
							widthArray.forEach(w=>{
								let unique = true;
								param_textsdata.forEach(p=>{
									if(p.width == w){
										p.count++;
										unique = false;
									}
								});
								if(unique){
									param_textsdata.push({
										width: w,
										count: 1
									});
								}
							});
							let paramtext = "";
							param_textsdata.forEach((t,i)=>{
								paramtext += t.count+"巾（W"+(t.width+padding_horizontal*2)+"mm） × H"+(wall.dimension.height+(padding_top+padding_bottom))+"mm<br>";
							});
							if(param_alloc_value) param_alloc_value.innerHTML = paramtext;
							this.param_alloc_value_text = paramtext;
							this.is_alloc_panel = true;
						}
					}}
				}
			}
			if(wall.settings.material == "wallpanel"){
				root.style.setProperty('--allocWidth',this.alloc_width+"px");
				this.alloc_rolls = [];
				const paramtext = "W"+this.alloc_width+"mm × H"+this.alloc_height+"mm";
				if(param_alloc_value) param_alloc_value.innerHTML = paramtext;
				this.param_alloc_value_text = paramtext;
				const total = document.createElement("div");
				const total_label = document.createElement("div");
				total.classList.add("allocate__scale_horizontal__full_width");
				total_label.classList.add("allocate__scale_horizontal__full_width__label");
				total_label.innerText = this.alloc_width;
				total.append(total_label);
				alloc_scale_h.append(total);
			}else{
				if(!this.is_alloc_panel){
					const define_roll_width = ()=>{
						if(wall.settings.material == "interior_sheet" && wall.settings.interiorsheet_method == "normal"){
							return wall.allocation.rollwidth;
						}else{
							return settings.defaultRollWidth;
						}
					}
					const roll_width = define_roll_width();
					const rolls_count = Math.ceil((wall.dimension.width+padding_horizontal*2)/roll_width);
					const alloc_width = rolls_count*roll_width;
					root.style.setProperty('--allocWidth',alloc_width+"px");
					this.alloc_rolls = [];
					for (var i=0; i<rolls_count; i++) {
						const roll = document.createElement("div");
						roll.classList.add("allocate__rolls__roll");
						roll.style.width = "calc(calc("+roll_width+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+") + 2px)";
						roll.style.left = "calc("+(roll_width*i)+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+")";
						alloc_rolls.append(roll);

						const partial = document.createElement("div");
						const number = document.createElement("div");
						const size = document.createElement("div");
						number.classList.add("number");
						let label = "";
						if(wall.allocation.origin == "left"){
							//label = (20>i) ? circle_num[i] : (i+1);
							label = "分割"+(i+1);
						}else{
							//const indx = (rolls_count-i-1);
							//label = (20>indx) ? circle_num[indx] : (indx+1);
							label = "分割"+(rolls_count-i);
						}
						number.innerHTML = label;
						this.alloc_rolls.push({
							label:label,
							width: roll_width
						});
						size.classList.add("size");
						size.innerText = roll_width;
						partial.append(number);
						partial.append(size);
						partial.style.width = "calc(calc("+roll_width+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+") + 1px)";
						partial.style.left = "calc("+(roll_width*i)+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+")";
						partial.classList.add("allocate__scale_horizontal__partial_width__partial");
						alloc_scale_h_partial.append(partial);
					}
					const paramtext = rolls_count+"巾（W"+roll_width+"mm） × H"+(wall.dimension.height+(padding_top+padding_bottom))+"mm";
					if(param_alloc_value) param_alloc_value.innerText = paramtext;
					this.param_alloc_value_text = paramtext;
				}
			}
			if(wall.allocation.origin == "left"){
				editor.setAttribute("data-alloc-origin", "left");
			}else if(wall.allocation.origin == "center"){
				editor.setAttribute("data-alloc-origin", "center");
			}else if(wall.allocation.origin == "other"){
				editor.setAttribute("data-alloc-origin", "other");
			}else{
				editor.setAttribute("data-alloc-origin", "right");
			}

			//什器・建具
			furniture.innerHTML = "";
			wall.furniture.forEach(frntr=>{
				const block = document.createElement("div");
				const label = document.createElement("div");
				block.classList.add("furniture__block");
				block.style.width = "calc("+frntr.width+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+")";
				block.style.height = "calc("+frntr.height+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+")";
				block.style.setProperty(frntr.origin_x, "calc("+frntr.position_x+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+")");
				block.style.setProperty(frntr.origin_y, "calc("+frntr.position_y+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+")");
				label.classList.add("furniture__block__label");
				label.innerHTML = "<span class='name'>"+frntr.label+"</span>";
				label.innerHTML += "<span class='dimension'>W"+frntr.width+"mm × H"+frntr.height+"mm"+"</span>";
				block.append(label);
				furniture.append(block);
			});

			//トンボスケール
			const tombo_vertical_label = editor_root.querySelector(".scale_vertical__tombo__label");
			const tombo_horizontal_label = editor_root.querySelector(".scale_horizontal__tombo__label");
			const tombo_horizontal = (wall.settings.material == "wallpanel") ? 150 : 50;
			const tombo_vertical = (wall.settings.material == "wallpanel") ? 150 : 50;
			tombo_vertical_label.innerText = tombo_vertical;
			tombo_horizontal_label.innerText = tombo_horizontal;
			root.style.setProperty('--tomboH', tombo_horizontal+"px");
			root.style.setProperty('--tomboV', tombo_vertical+"px");

			if(this.isEditor){
				setConstructorAutoinputText();
				setFactoryAutoinputText();
				validateErrors();
				save();
			}
		}


		/*
			editorpannel
			編集メニューパネル
		*/
		const editorpannelDoms = document.querySelectorAll(".editorpannel__panel");
		const editorpannels = [];
		const pannel_pulldown = document.querySelectorAll(".editorpannel__section__select");
		const attachPulldownChangeEvent = (pulldown)=>{
			const label = pulldown.querySelector("label");
			const select = pulldown.querySelector("select");
			select.addEventListener("change", ()=>{
				label.innerText = pulldown.querySelector("option[value='"+select.value+"']").innerText;
			}, false);
		}
		const initEditorPannels = ()=>{
			editorpannelDoms.forEach(editorpannel=>{
				const panel = function(editorpannel){
					const pannelToggleBtn = editorpannel.querySelector(".editorpannel__toggle");
					const pannelBg = editorpannel.querySelector(".editorpannel__bg");
					let isOpen = false;
					const togglePanelOpen = ()=>{
						if(isOpen){
							closePanel();
						}else{
							openPanel();
						}
					}
					const openPanel = ()=>{
						const addOpenPanelFlag = Array.from(editorpannelDoms).some(elm=>elm.classList.contains("open")) || document.body.classList.contains("preventPanelOpenAnim");
						editorpannels.forEach(p=>p.close());
						editorpannel.classList.add("open");
						isOpen = true;
						ctrl.isSettingPanelOpen = true;
						if(addOpenPanelFlag) document.body.classList.add("preventPanelOpenAnim");
					}
					const closePanel = ()=>{
						editorpannel.classList.remove("open");
						isOpen = false;
						ctrl.isSettingPanelOpen = false;
						if(!Array.from(editorpannelDoms).some(elm=>elm.classList.contains("open"))){
							document.body.classList.remove("preventPanelOpenAnim");
						}
					}
					pannelToggleBtn.addEventListener("click", togglePanelOpen, false);
					pannelBg.addEventListener("click", togglePanelOpen, false);

					this.open = openPanel;
					this.close = closePanel;
					return this;
				};
				editorpannels.push(new panel(editorpannel));
			});
			pannel_pulldown.forEach(pulldown=>attachPulldownChangeEvent(pulldown));

			if(this.isEditor){
				const actual_width_min_label = document.querySelector("#design_actual_size_invalid .width_min");
				const actual_width_max_label = document.querySelector("#design_actual_size_invalid .width_max");
				const actual_height_min_label = document.querySelector("#design_actual_size_invalid .height_min");
				const actual_height_max_label = document.querySelector("#design_actual_size_invalid .height_max");
				const variable_width_min_label = document.querySelector("#design_variable_size_invalid .width_min");
				const variable_width_max_label = document.querySelector("#design_variable_size_invalid .width_max");
				const variable_height_min_label = document.querySelector("#design_variable_size_invalid .height_min");
				const variable_height_max_label = document.querySelector("#design_variable_size_invalid .height_max");
				if(wall.wallpaper.tolerance.actual.min === 0){
					actual_height_min_label.innerText = "下限なし";
					actual_width_min_label.innerText = "下限なし";
				}else{
					actual_height_min_label.innerText = wall.wallpaper.tolerance.actual.min+"mm";
					actual_width_min_label.innerText = Math.round(wall.wallpaper.tolerance.actual.min * wall.wallpaper.dimension.natural_width/wall.wallpaper.dimension.natural_height)+"mm";
				}
				if(wall.wallpaper.tolerance.actual.max === true || wall.wallpaper.tolerance.actual.max >= Number.MAX_SAFE_INTEGER){
					actual_height_max_label.innerText = "上限なし";
					actual_width_max_label.innerText = "上限なし";
				}else{
					actual_height_max_label.innerText = wall.wallpaper.tolerance.actual.max+"mm";
					actual_width_max_label.innerText = Math.round(wall.wallpaper.tolerance.actual.max * wall.wallpaper.dimension.natural_width/wall.wallpaper.dimension.natural_height)+"mm";
				}
				variable_width_min_label.innerText = (wall.wallpaper.tolerance.variable.width.min === 0) ? "下限なし" : wall.wallpaper.tolerance.variable.width.min+"mm";
				variable_width_max_label.innerText = (wall.wallpaper.tolerance.variable.width.max === true || wall.wallpaper.tolerance.variable.width.max >= Number.MAX_SAFE_INTEGER) ? "上限なし" : wall.wallpaper.tolerance.variable.width.max+"mm";
				variable_height_min_label.innerText = (wall.wallpaper.tolerance.variable.height.min === 0) ? "下限なし" : wall.wallpaper.tolerance.variable.height.min+"mm";
				variable_height_max_label.innerText = (wall.wallpaper.tolerance.variable.height.max === true || wall.wallpaper.tolerance.variable.height.max >= Number.MAX_SAFE_INTEGER) ? "上限なし" : wall.wallpaper.tolerance.variable.height.max+"mm";
			}
		}
		

		/* 編集イベント定義 */
		const nameSettingUpdatedHandler = ()=>{
			wallname_param.innerText = wallname_panel_input.value;
			save();
		}
		const mediatype_panelmethod_change_handler = ()=>{
			if(wall.settings.material == "interior_sheet" && wall.settings.interiorsheet_method == "panel"){
				editor.setAttribute("data-tombo-visible", false);
				wall.allocation.origin = "other";
				alloc_origin_inputs.forEach(input=>{
					if(input.value == wall.allocation.origin){
						input.checked = true;
						input.dispatchEvent(new Event('change'))
					}
				});
				setScaleFurniturePanel();
			}else if(wall.settings.material == "wallpanel"){
				editor.setAttribute("data-tombo-visible", true);
				wall.allocation.origin = "right";
				alloc_origin_inputs.forEach(input=>{
					if(input.value == wall.allocation.origin){
						input.checked = true;
						input.dispatchEvent(new Event('change'))
					}
				});
				setScaleFurniturePanel();
			}else{
				editor.setAttribute("data-tombo-visible", true);
				wall.allocation.origin = "right";
				alloc_origin_inputs.forEach(input=>{
					if(input.value == wall.allocation.origin){
						input.checked = true;
						input.dispatchEvent(new Event('change'))
					}
				});
				setScaleFurniturePanel();
			}
		}
		const wallSettingUpdatedHandler = ()=>{
			panelSettingToggleVisibility();
			setScaleFurniturePanel();
			setConstructorAutoinputText();
			save();
		}
		const designSettingUpdatedHandler = ()=>{
			update_params();
			panelSettingToggleVisibility();
			setScaleFurniturePanel();
			applyFillColorSettings();
			initRepeatSetting();
			setFactoryAutoinputText();
			setConstructorAutoinputText();
			save();
		}
		const panelSettingToggleVisibility = ()=>{
			if(wall.settings.material == "wallpaper"){
				walldimension_label.innerText = "施工寸法";
				wall_interiorsheet_method.style.display = "none";
				corner_panel.style.display = "block";
				panel_panel.style.display = "none";
				furniture_panel.style.display = "block";
				furniture.style.visibility = "visible";
				toggleFurnitureBtn.style.display = "block";
				alloc_origin.style.display = "block";
				alloc_padding.style.display = "block";
				alloc_overpadding.style.display = "none";
				alloc_panelloss.style.display = "none";
				alloc_rollwidth.style.display = "none";
				alloc_tombo.style.display = "block";
				allocation_constructor.style.display = "block";
				//toggleAllocHandler(false);
				toggleFurnitureHandler(isFurnitureVisible);
			}
			if(wall.settings.material == "interior_sheet"){
				if(wall.settings.interiorsheet_method == "normal"){
					corner_panel.style.display = "block";
					panel_panel.style.display = "none";
					alloc_origin.style.display = "block";
					alloc_padding.style.display = "block";
					alloc_overpadding.style.display = "none";
					alloc_panelloss.style.display = "none";
					alloc_rollwidth.style.display = "block";
					alloc_tombo.style.display = "block";
					allocation_constructor.style.display = "block";
				}
				if(wall.settings.interiorsheet_method == "panel"){
					corner_panel.style.display = "none";
					panel_panel.style.display = "block";
					alloc_origin.style.display = "none";
					alloc_padding.style.display = "none";
					alloc_overpadding.style.display = "block";
					alloc_panelloss.style.display = "none";
					alloc_rollwidth.style.display = "none";
					alloc_tombo.style.display = "none";
					allocation_constructor.style.display = "block";
				}
				walldimension_label.innerText = "施工寸法";
				wall_interiorsheet_method.style.display = "block";
				furniture_panel.style.display = "block";
				furniture.style.visibility = "visible";
				toggleFurnitureBtn.style.display = "block";
				// toggleAllocHandler(false);
				toggleFurnitureHandler(isFurnitureVisible);
			}
			if(wall.settings.material == "wallpanel"){
				walldimension_label.innerText = "パネルサイズ";
				wall_interiorsheet_method.style.display = "none";
				corner_panel.style.display = "none";
				panel_panel.style.display = "none";
				furniture_panel.style.display = "none";
				furniture.style.visibility = "hidden";
				toggleFurnitureBtn.style.display = "none";
				alloc_origin.style.display = "none";
				alloc_padding.style.display = "none";
				alloc_overpadding.style.display = "none";
				alloc_panelloss.style.display = "block";
				alloc_rollwidth.style.display = "none";
				alloc_tombo.style.display = "none";
				allocation_constructor.style.display = "none";
				// toggleAllocHandler(false);
				toggleFurnitureHandler(false);
			}
			save();
		}
		const layoutSettingUpdatedHandler = ()=>{
			update_params();
			save();
		}
		const layoutEditorUpdatedHandler = ()=>{
			layout_dimension_w_input.value = wall.wallpaper.dimension.width;
			layout_dimension_h_input.value = wall.wallpaper.dimension.height;
			layout_position_x_input.value = wall.wallpaper.position.x;
			layout_position_y_input.value = wall.wallpaper.position.y;
			save();
		}
		const allocSettingUpdatedHandler = ()=>{
		}

		/*
			編集メニューパネル - 壁面設定
		*/
		/* 壁面名 編集欄 ここから */
			const wallname_panel_input = document.querySelector(".panel_wall__name input");
			const wallname_param = document.querySelector(".param__wall-value");
			const initPanellWallname = ()=>{
				wallname_panel_input.value = wall.settings.name;
				wallname_param.innerText = wallname_panel_input.value;
				wallname_panel_input.addEventListener("change", ()=>{
					wall.settings.name = wallname_panel_input.value;
					nameSettingUpdatedHandler();
				}, false);
			}
		/* 壁面名 編集欄 ここまで */

		/* 施工寸法 編集欄 ここから */
			const walldimension_label = document.querySelector(".panel_wall__dimension .editorpannel__section__label");
			const wallwidth_panel_input = document.querySelector("input#wall_dimension_width");
			const wallheight_panel_input = document.querySelector("input#wall_dimension_height");
			const initPanelWallDimension = ()=>{
				wallwidth_panel_input.value = wall.dimension.width;
				wallheight_panel_input.value = wall.dimension.height;
				wallwidth_panel_input.addEventListener("change", ()=>{
					wall.dimension.width = wallwidth_panel_input.value*1;
					root.style.setProperty('--wallWidth',wall.dimension.width+"px");
					wallSettingUpdatedHandler();
				}, false);
				wallheight_panel_input.addEventListener("change", ()=>{
					wall.dimension.height = wallheight_panel_input.value*1;
					root.style.setProperty('--wallHeight',wall.dimension.height+"px");
					const panelheights = document.querySelectorAll(".panel_wall__panel__item__height input");
					panelheights.forEach(phi=>{ phi.value = wall.dimension.height; });
					wallSettingUpdatedHandler();
				}, false);
			}
		/* 施工寸法 編集欄 ここまで */

		/* インテリアシート施工方法 編集欄 ここから */
			const wall_interiorsheet_method = document.querySelector(".panel_wall__method");
			const wall_interiorsheet_method_inputs = document.querySelectorAll("input[name='wall_interiorsheet_method']");
			const wall_method_change_handler = ()=>{
				const selected = document.querySelector("input[name='wall_interiorsheet_method']:checked");
				wall.settings.interiorsheet_method = selected.value;
				wallSettingUpdatedHandler();
				mediatype_panelmethod_change_handler();
			}
			const initPanelInteriorSheetMethod = ()=>{
				wall_interiorsheet_method_inputs.forEach(method=>{
					method.checked = (method.value == wall.settings.interiorsheet_method);
					method.addEventListener("change", wall_method_change_handler, false);
				});
			}
		/* インテリアシート施工方法 編集欄 ここから */

		/* 入隅・出隅 編集欄 ここから */
			const corner_panel = document.querySelector(".editorpannel__section.panel_wall__corners");
			const corner_panel_addBtn = document.querySelector(".panel_wall__corners .editorpannel__section__repeater__add");
			const corner_panel_entries = document.querySelector(".panel_wall__corners .editorpannel__section__repeater__entries");
			let corner_item_id = 0;
			const add_corner = (type="inner",origin="left",value=0)=>{
				const corner_item_dom = this.Factory.cornerItem(corner_item_id);
				const pulldown = corner_item_dom.querySelectorAll(".editorpannel__section__select");
				const type_input = corner_item_dom.querySelector(".panel_wall__corners__item__type select");
				const origin_input = corner_item_dom.querySelector(".panel_wall__corners__item__origin select");
				const value_input = corner_item_dom.querySelector(".panel_wall__corners__item__size input");
				const remove = corner_item_dom.querySelector(".editorpannel__section__repeater__entries__item__del");
				corner_panel_entries.append(corner_item_dom);
				pulldown.forEach(pd=>attachPulldownChangeEvent(pd));
				type_input.value = type;
				origin_input.value = origin;
				value_input.value = value;
				type_input.dispatchEvent(new Event('change'));
				origin_input.dispatchEvent(new Event('change'));
				type_input.addEventListener("change", update_corner, false);
				origin_input.addEventListener("change", update_corner, false);
				value_input.addEventListener("change", update_corner, false);
				remove.addEventListener("click", ()=>{
					corner_item_dom.remove();
					update_corner();
				}, false);
				corner_item_id++;
			}
			const update_corner = ()=>{
				const new_corners = [];
				const corner_items = corner_panel_entries.querySelectorAll(".editorpannel__section__repeater__entries__item");
				corner_items.forEach(item=>{
					const item_type = item.querySelector(".panel_wall__corners__item__type select");
					const item_origin = item.querySelector(".panel_wall__corners__item__origin select");
					const item_value = item.querySelector(".panel_wall__corners__item__size input");
					const corner = {};
					corner.type = item_type.value;
					corner.origin = item_origin.value;
					corner.position = item_value.value;
					new_corners.push(corner);
				});
				wall.corner = new_corners;
				wallSettingUpdatedHandler();
			}
			const initPanelCorners = ()=>{
				if(Array.isArray(wall.corner)){
					for(let i=0; i<wall.corner.length; i++){
						add_corner(wall.corner[i].type, wall.corner[i].origin, wall.corner[i].position);
					}
				}
				corner_panel_addBtn.addEventListener("click", ()=>{
					add_corner();
					update_corner();
				}, false);
			}
		/* 入隅・出隅 編集欄 ここまで */

		/* 施工寸法（パネル）編集欄 ここから */
			const panel_panel = document.querySelector(".editorpannel__section.panel_wall__panel");
			const panel_panel_addBtn = document.querySelector(".panel_wall__panel .editorpannel__section__repeater__add");
			const panel_panel_entries = document.querySelector(".panel_wall__panel .editorpannel__section__repeater__entries");
			const panel_panel_total = document.querySelector(".panel_wall__panel__total__amount");
			let panel_item_id = 0;
			const add_panel = (origin="left",height=wall.dimension.height,width=0,positionID=null)=>{
				const panel_item_dom = this.Factory.panelItem(panel_item_id);
				const pulldown = panel_item_dom.querySelectorAll(".editorpannel__section__select");
				const origin_input = panel_item_dom.querySelector(".panel_wall__panel__item__origin select");
				const width_input = panel_item_dom.querySelector(".panel_wall__panel__item__width input");
				const height_input = panel_item_dom.querySelector(".panel_wall__panel__item__height input");
				const duplicate = panel_item_dom.querySelector(".panel_wall__panel__item__duplicate__btn");
				const remove = panel_item_dom.querySelector(".editorpannel__section__repeater__entries__item__del");
				let insertPosition = null
				if(positionID != null){
					insertPosition = panel_panel_entries.querySelector(".editorpannel__section__repeater__entries__item[data-number='"+positionID+"']");
				}
				if(insertPosition){
					panel_panel_entries.insertBefore(panel_item_dom, insertPosition.nextSibling);
				}else{
					panel_panel_entries.append(panel_item_dom);	
				}
				pulldown.forEach(pd=>attachPulldownChangeEvent(pd));
				origin_input.value = origin;
				width_input.value = width;
				height_input.value = height;
				origin_input.dispatchEvent(new Event('change'));
				origin_input.addEventListener("change", ()=>{
					const all_origin = document.querySelectorAll(".panel_wall__panel__item__origin");
					all_origin.forEach(ao=>{
						const label = ao.querySelector("label");
						const select = ao.querySelector("select");
						select.value = origin_input.value;
						label.innerText = ao.querySelector("option[value='"+select.value+"']").innerText;
					});
				}, false);
				height_input.addEventListener("change", ()=>{
					const all_height = document.querySelectorAll(".panel_wall__panel__item__height input");
					all_height.forEach(ah=>{
						ah.value = height_input.value;
					});
				}, false);
				origin_input.addEventListener("change", update_panel, false);
				width_input.addEventListener("change", update_panel, false);
				height_input.addEventListener("change", update_panel, false);
				duplicate.addEventListener("click", ()=>{
					const panelitem = duplicate.closest(".editorpannel__section__repeater__entries__item");
					add_panel(origin_input.value, height_input.value, width_input.value, panelitem.dataset.number);
					update_panel();
				}, false);
				remove.addEventListener("click", ()=>{
					panel_item_dom.remove();
					update_panel();
				}, false);
				panel_item_id++;
			}
			const update_panel = ()=>{
				const new_panels = [];
				const panel_items = panel_panel_entries.querySelectorAll(".editorpannel__section__repeater__entries__item");
				panel_items.forEach(item=>{
					const item_origin = item.querySelector(".panel_wall__panel__item__origin select");
					const item_height = item.querySelector(".panel_wall__panel__item__height input");
					const item_width = item.querySelector(".panel_wall__panel__item__width input");
					new_panels.push(item_width.value*1);
				});
				wall.panels.origin = panel_items.item(0)?.querySelector(".panel_wall__panel__item__origin select").value;
				wall.panels.height = panel_items.item(0)?.querySelector(".panel_wall__panel__item__height input").value;
				wall.panels.width = new_panels;
				panel_panel_total.innerText = wall.panels.width.reduce((sum,elm)=>{ return sum+elm; }, 0);
				wallSettingUpdatedHandler();
			}
			const initPanelPanels = ()=>{
				if(Array.isArray(wall.panels?.width)){
					for(let i=0; i<wall.panels.width.length; i++){
						add_panel(wall.panels.origin, wall.dimension.height, wall.panels.width[i]);
					}
				}
				panel_panel_total.innerText = wall.panels.width.reduce((sum,elm)=>{ return sum+elm; }, 0);
				panel_panel_addBtn.addEventListener("click", ()=>{
					const panel = panel_panel_entries.querySelector(".editorpannel__section__repeater__entries__item");
					if(panel){
						const origin = panel.querySelector(".panel_wall__panel__item__origin select");
						const height = panel.querySelector(".panel_wall__panel__item__height input");
						add_panel(origin.value, height.value);
					}else{
						add_panel();
					}
					update_panel();
				}, false);
			}
		/* 施工寸法（パネル）編集欄 ここまで */

		/* 建具・什器 編集欄 ここから */
			const furniture_panel = document.querySelector(".editorpannel__section.panel_wall__furniture");
			const furniture_panel_addBtn = document.querySelector(".panel_wall__furniture .editorpannel__section__repeater__add");
			const furniture_panel_entries = document.querySelector(".panel_wall__furniture .editorpannel__section__repeater__entries");
			let furniture_item_id = 0;
			const add_furniture = (
				label="カウンター",
				width=2000,
				height=1000,
				origin_x="left",
				position_x=900,
				origin_y="bottom",
				position_y=0,
			)=>{
				const furniture_item_dom = this.Factory.furnitureItem(furniture_item_id);
				const pulldown = furniture_item_dom.querySelectorAll(".editorpannel__section__select");
				const label_input = furniture_item_dom.querySelector(".panel_wall__furniture__item__name input");
				const width_input = furniture_item_dom.querySelector(".panel_wall__furniture__item__width input");
				const height_input = furniture_item_dom.querySelector(".panel_wall__furniture__item__height input");
				const origin_x_input = furniture_item_dom.querySelector(".panel_wall__furniture__item__horizontal select");
				const position_x_input = furniture_item_dom.querySelector(".panel_wall__furniture__item__horizontal input");
				const origin_y_input = furniture_item_dom.querySelector(".panel_wall__furniture__item__vertical select");
				const position_y_input = furniture_item_dom.querySelector(".panel_wall__furniture__item__vertical input");
				const remove = furniture_item_dom.querySelector(".editorpannel__section__repeater__entries__item__del");
				furniture_panel_entries.append(furniture_item_dom);
				pulldown.forEach(pd=>attachPulldownChangeEvent(pd));
				label_input.value = label;
				width_input.value = width;
				height_input.value = height;
				origin_x_input.value = origin_x;
				position_x_input.value = position_x;
				origin_y_input.value = origin_y;
				position_y_input.value = position_y;
				origin_x_input.dispatchEvent(new Event('change'));
				origin_y_input.dispatchEvent(new Event('change'));
				label_input.addEventListener("change", update_furniture, false);
				width_input.addEventListener("change", update_furniture, false);
				height_input.addEventListener("change", update_furniture, false);
				origin_x_input.addEventListener("change", update_furniture, false);
				position_x_input.addEventListener("change", update_furniture, false);
				origin_y_input.addEventListener("change", update_furniture, false);
				position_y_input.addEventListener("change", update_furniture, false);
				remove.addEventListener("click", ()=>{
					furniture_item_dom.remove();
					update_furniture();
				}, false);
				furniture_item_id++;
			}
			const update_furniture = ()=>{
				const new_furnitures = [];
				const furniture_items = furniture_panel_entries.querySelectorAll(".editorpannel__section__repeater__entries__item");
				furniture_items.forEach(item=>{
					const item_label = item.querySelector(".panel_wall__furniture__item__name input");
					const item_width = item.querySelector(".panel_wall__furniture__item__width input");
					const item_height = item.querySelector(".panel_wall__furniture__item__height input");
					const item_origin_x = item.querySelector(".panel_wall__furniture__item__horizontal select");
					const item_position_x = item.querySelector(".panel_wall__furniture__item__horizontal input");
					const item_origin_y = item.querySelector(".panel_wall__furniture__item__vertical select");
					const item_position_y = item.querySelector(".panel_wall__furniture__item__vertical input");
					const furniture = {};
					furniture.label = item_label.value;
					furniture.width = item_width.value;
					furniture.height = item_height.value;
					furniture.origin_x = item_origin_x.value;
					furniture.position_x = item_position_x.value;
					furniture.origin_y = item_origin_y.value;
					furniture.position_y = item_position_y.value;
					new_furnitures.push(furniture);
				});
				wall.furniture = new_furnitures;
				wallSettingUpdatedHandler();
			}
			const initPanelFurniture = ()=>{
				if(Array.isArray(wall.furniture)){
					for(let i=0; i<wall.furniture.length; i++){
						add_furniture(
							wall.furniture[i].label,
							wall.furniture[i].width,
							wall.furniture[i].height,
							wall.furniture[i].origin_x,
							wall.furniture[i].position_x,
							wall.furniture[i].origin_y,
							wall.furniture[i].position_y
						);
					}
				}
				furniture_panel_addBtn.addEventListener("click", ()=>{
					add_furniture();
					update_furniture();
				}, false);
			}
		/* 建具・什器 編集欄 ここまで */

		/*
			編集メニューパネル - 壁紙設定
		*/
		/* 使用壁紙 編集欄 ここから */
			const wall_design_input = document.querySelector("#wallpaper_product");
			const wall_material_input = document.querySelector("#wallpaper_material");
			const wall_mediatype_input = document.querySelector("#wallpaper_mediatype");
			const wall_material_line = document.querySelector(".panel_wallpaper__material");
			const wall_mediatype_line = document.querySelector(".panel_wallpaper__mediatype");
			const toggleWallLineVisibility = ()=>{
				if(wall.settings.material=="wallpaper"){
					wall_mediatype_line.style.display = "block";
				}else{
					wall_mediatype_line.style.display = "none";
				}
			}
			const initPanelWallpaper = ()=>{
				const variationPulldownOption = wall_design_input.querySelector("option");
				variationPulldownOption.setAttribute("value", wall.wallpaper.catno);
				variationPulldownOption.innerText = wall.wallpaper.catno;
				wall_design_input.value = wall.wallpaper.catno;
				wall_design_input.dispatchEvent(new Event('change'));
				const promise = window.WallpaperData.getPromise();
				promise.then(()=>{
					variationPulldownOption.remove();
					this.variations = window.WallpaperData.getSeries(wall.wallpaper.catno);
					this.variations.forEach(item=>{
						const option = document.createElement("option");
						option.setAttribute("value", item.catno);
						option.innerText = item.catno;
						if(item.catno == wall.wallpaper.catno) option.setAttribute("selected", "selected");
						wall_design_input.append(option);
					});
				});
				promise.catch(err => {
					wall_design_input.setAttribute("disabled", "disabled");
					wall_design_input.style.pointerEvents = "none";
				});

				if(wall.wallpaper.allow_wallpanel === false){
					const material_selection_wallpanel = wall_material_input.querySelector("option[value='wallpanel']");
					material_selection_wallpanel.setAttribute("disabled", "disabled");
					if(wall.settings.material == "wallpanel"){
						wall.settings.material = "wallpaper";
					}
				}

				wall_material_input.value = wall.settings.material;
				wall_mediatype_input.value = wall.settings.mediatype;
				wall_material_input.dispatchEvent(new Event('change'));
				wall_mediatype_input.dispatchEvent(new Event('change'));
				wall_design_input.addEventListener("change", ()=>{
					//壁紙商品変更
					const selectedWallpaper = this.variations.find(item=>item.catno==wall_design_input.value);
					if(selectedWallpaper){
						wall.wallpaper.catno = selectedWallpaper.catno;
						wall.wallpaper.imgPath = selectedWallpaper.imgPath;
						wall.wallpaper.fillcolor.top = selectedWallpaper.fillcolor.top;
						wall.wallpaper.fillcolor.right = selectedWallpaper.fillcolor.right;
						wall.wallpaper.fillcolor.bottom = selectedWallpaper.fillcolor.bottom;
						wall.wallpaper.fillcolor.left = selectedWallpaper.fillcolor.left;
						// editor.classList.add("editor_repaint");
					}
					designSettingUpdatedHandler();
				}, false);
				wall_material_input.addEventListener("change", ()=>{
					wall.settings.material = wall_material_input.value;
					toggleWallLineVisibility();
					designSettingUpdatedHandler();
					mediatype_panelmethod_change_handler();
				}, false);
				wall_mediatype_input.addEventListener("change", ()=>{
					wall.settings.mediatype = wall_mediatype_input.value;
					save();
				}, false);
				toggleWallLineVisibility();
			}
		/* 使用壁紙 編集欄 ここまで */

		/* レイアウト 編集欄 ここから */
			const layout_dimension_w_input = document.querySelector("#wallpaper_dimension_width");
			const layout_dimension_h_input = document.querySelector("#wallpaper_dimension_height");
			const layout_position_x_input = document.querySelector("#wallpaper_position_x");
			const layout_position_y_input = document.querySelector("#wallpaper_position_y");

			const layout_fill = document.querySelector(".panel_wallpaper__fill");
			const layout_fill_top_input = document.querySelector("input[name='wallpaper_fill'][value='top']");
			const layout_fill_bottom_input = document.querySelector("input[name='wallpaper_fill'][value='bottom']");
			const layout_fill_left_input = document.querySelector("input[name='wallpaper_fill'][value='left']");
			const layout_fill_right_input = document.querySelector("input[name='wallpaper_fill'][value='right']");

			const layout_reverse_repeat = document.querySelector(".panel_wallpaper__reverse");
			const layout_reverse_repeat_input = document.querySelector("input[name='wallpaper_reverse']");
			const layout_variable_scale = document.querySelector(".panel_wallpaper__variablescale");
			const layout_variable_scale_input = document.querySelector("input[name='wallpaper_variablescale']");

			const layout_repeat = document.querySelector(".panel_wallpaper__repeat");
			const layout_repeat_horizontal = document.querySelector("input[name='wallpaper_repeat'][value='horizontal']");
			const layout_repeat_vertical = document.querySelector("input[name='wallpaper_repeat'][value='vertical']");
			
			const initPanelLayout = ()=>{
				if(
					wall.wallpaper.tolerance.variable.width.min === false || 
					wall.wallpaper.tolerance.variable.width.max === false || 
					wall.wallpaper.tolerance.variable.height.min === false || 
					wall.wallpaper.tolerance.variable.height.max === false
				){
					wall.wallpaper.allow_variable_scale = false;
					layout_variable_scale.style.display = "none";
				}
				layout_variable_scale_input.checked = wall.wallpaper.allow_variable_scale;
				layout_variable_scale_input.addEventListener("change", ()=>{
					wall.wallpaper.allow_variable_scale = layout_variable_scale_input.checked;
					if(!wall.wallpaper.allow_variable_scale){
						//wall.wallpaper.dimension.height = Math.round(wall.wallpaper.dimension.width * wall.wallpaper.dimension.natural_height/wall.wallpaper.dimension.natural_width);
						containDimension(wall.wallpaper.dimension.width, wall.wallpaper.dimension.height, true);
					}
					layoutSettingUpdatedHandler();
					layoutEditorUpdatedHandler();
					toggleScaleHandleVisibility();
				}, false);

				layout_dimension_w_input.value = wall.wallpaper.dimension.width;
				layout_dimension_h_input.value = wall.wallpaper.dimension.height;
				layout_position_x_input.value = wall.wallpaper.position.x;
				layout_position_y_input.value = wall.wallpaper.position.y;
				layout_dimension_w_input.addEventListener("change", ()=>{
					wall.wallpaper.dimension.width = layout_dimension_w_input.value*1;
					if(!wall.wallpaper.allow_variable_scale){
						wall.wallpaper.dimension.height = Math.round(wall.wallpaper.dimension.width * wall.wallpaper.dimension.natural_height/wall.wallpaper.dimension.natural_width);
						containDimension(wall.wallpaper.dimension.width, wall.wallpaper.dimension.height, true);
						layout_dimension_h_input.value = wall.wallpaper.dimension.height;
					}
					layoutSettingUpdatedHandler();
					layoutEditorUpdatedHandler();
				}, false);
				layout_dimension_h_input.addEventListener("change", ()=>{
					wall.wallpaper.dimension.height = layout_dimension_h_input.value*1;
					if(!wall.wallpaper.allow_variable_scale){
						wall.wallpaper.dimension.width = Math.round(wall.wallpaper.dimension.height * wall.wallpaper.dimension.natural_width/wall.wallpaper.dimension.natural_height);
						containDimension(wall.wallpaper.dimension.width, wall.wallpaper.dimension.height, true);
						layout_dimension_w_input.value = wall.wallpaper.dimension.width;
					}
					layoutSettingUpdatedHandler();
					layoutEditorUpdatedHandler();
				}, false);
				layout_position_x_input.addEventListener("change", ()=>{
					wall.wallpaper.position.x = layout_position_x_input.value*1;
					layoutSettingUpdatedHandler();
				}, false);
				layout_position_y_input.addEventListener("change", ()=>{
					wall.wallpaper.position.y = layout_position_y_input.value*1;
					layoutSettingUpdatedHandler();
				}, false);

				if(!wall.wallpaper.fillcolor.top) layout_fill_top_input.closest("label").style.display = "none";
				if(!wall.wallpaper.fillcolor.bottom) layout_fill_bottom_input.closest("label").style.display = "none";
				if(!wall.wallpaper.fillcolor.left) layout_fill_left_input.closest("label").style.display = "none";
				if(!wall.wallpaper.fillcolor.right) layout_fill_right_input.closest("label").style.display = "none";
				if(!wall.wallpaper.fillcolor.top && !wall.wallpaper.fillcolor.bottom && !wall.wallpaper.fillcolor.left && !wall.wallpaper.fillcolor.right){
					layout_fill.style.display = "none";
				}
				if(wall.wallpaper.fillcolor.top && wall.wallpaper.fillactive.top) layout_fill_top_input.checked = true;
				if(wall.wallpaper.fillcolor.bottom && wall.wallpaper.fillactive.bottom) layout_fill_bottom_input.checked = true;
				if(wall.wallpaper.fillcolor.left && wall.wallpaper.fillactive.left) layout_fill_left_input.checked = true;
				if(wall.wallpaper.fillcolor.right && wall.wallpaper.fillactive.right) layout_fill_right_input.checked = true;
				layout_fill_top_input.addEventListener("change", ()=>{
					wall.wallpaper.fillactive.top = layout_fill_top_input.checked;
					applyFillColorSettings();
				}, false);
				layout_fill_bottom_input.addEventListener("change", ()=>{
					wall.wallpaper.fillactive.bottom = layout_fill_bottom_input.checked;
					applyFillColorSettings();
				}, false);
				layout_fill_left_input.addEventListener("change", ()=>{
					wall.wallpaper.fillactive.left = layout_fill_left_input.checked;
					applyFillColorSettings();
				}, false);
				layout_fill_right_input.addEventListener("change", ()=>{
					wall.wallpaper.fillactive.right = layout_fill_right_input.checked;
					applyFillColorSettings();
				}, false);

				if(wall.wallpaper.reverseRepeat) layout_reverse_repeat_input.checked = true;
				if(wall.wallpaper.allow_reverseRepeat === false){
					wall.wallpaper.reverseRepeat = false;
					layout_reverse_repeat_input.checked = false;
					layout_reverse_repeat.style.display = "none";
				}
				layout_reverse_repeat_input.addEventListener("change", ()=>{
					wall.wallpaper.reverseRepeat = layout_reverse_repeat_input.checked;
					applyRepeatSettings();
					setFactoryAutoinputText();
				}, false);

				if(wall.wallpaper.repeat == "repeat"){
					layout_repeat_horizontal.checked = true;
					layout_repeat_vertical.checked = true;
				}else if(wall.wallpaper.repeat == "horizontal"){
					layout_repeat_horizontal.checked = true;
					layout_repeat_vertical.checked = false;

				}else if(wall.wallpaper.repeat == "vertical"){
					layout_repeat_horizontal.checked = false;
					layout_repeat_vertical.checked = true;
				}else{
					layout_repeat_horizontal.checked = false;
					layout_repeat_vertical.checked = false;
				}
				const repeatChange = ()=>{
					if(layout_repeat_horizontal.checked && layout_repeat_vertical.checked){
						wall.wallpaper.repeat = "repeat";
					}else if(!layout_repeat_horizontal.checked && layout_repeat_vertical.checked){
						wall.wallpaper.repeat = "vertical";
					}else if(layout_repeat_horizontal.checked && !layout_repeat_vertical.checked){
						wall.wallpaper.repeat = "horizontal";
					}else{
						wall.wallpaper.repeat = "none";
					}
					applyRepeatSettings();
					save();
				}
				layout_repeat_horizontal.addEventListener("change", repeatChange, false);
				layout_repeat_vertical.addEventListener("change", repeatChange, false);


				const toggleScaleHandleVisibility = ()=>{
					/*
					if(wall.wallpaper.allow_variable_scale){
						// 変倍
						if(wall.wallpaper.tolerance.variable.width.max == wall.wallpaper.tolerance.variable.width.min){
							instance.classList.add("disable_horizontal");
						}
						if(wall.wallpaper.tolerance.variable.height.max == wall.wallpaper.tolerance.variable.height.min){
							instance.classList.add("disable_vertical");
						}
					}else{
						// 等倍
						if(wall.wallpaper.tolerance.actual.max == wall.wallpaper.tolerance.actual.min){
							instance.classList.add("disable_horizontal", "disable_vertical");
						}
					}
					*/
				}
				toggleScaleHandleVisibility();
			}
		/* レイアウト 編集欄 ここまで */

		/*
			編集メニューパネル - 割付図設定
		*/
		/* 施工方向 編集欄 ここから */
			let stash_alloc_origin;
			const alloc_origin = document.querySelector(".panel_allocation__origin");
			const alloc_origin_inputs = document.querySelectorAll("input[name='allocation_origin']");
			const alloc_origin_change_handler = ()=>{
				const selected = document.querySelector("input[name='allocation_origin']:checked");
				wall.allocation.origin = selected.value;
				if(wall.allocation.tombo.type != wall.allocation.origin){
					wall.allocation.tombo.type = wall.allocation.origin
					alloc_tombo_type_input.value = wall.allocation.origin;
					alloc_tombo_type_input.dispatchEvent(new Event('change'));
					alloc_tombo_origin_input.dispatchEvent(new Event('change'));

					if(wall.allocation.origin == "right" || wall.allocation.origin == "center"){
						wall.allocation.padding = "left";
					}else if(wall.allocation.origin == "left"){
						wall.allocation.padding = "right";
					}
					alloc_padding_inputs.forEach(input=>{
						input.checked = (input.value == wall.allocation.padding);
					});
				}
				if(wall.allocation.origin == "left"){
					if(wall.allocation.padding == "left"){
						alloc_padding_inputs.forEach(input=>{
							input.checked = (input.value == "right");
						});
						alloc_padding_change_handler();
					}
				}else if(wall.allocation.origin == "right" || wall.allocation.origin == "center"){
					if(wall.allocation.padding == "right"){
						alloc_padding_inputs.forEach(input=>{
							input.checked = (input.value == "left");
						});
						alloc_padding_change_handler();
					}
				}
				stash_alloc_origin = wall.allocation.origin;
				setScaleFurniturePanel();
			}
			const initPanelAllocOrigin = ()=>{
				alloc_origin_inputs.forEach(input=>{
					input.checked = (input.value == wall.allocation.origin);
					input.addEventListener("change", alloc_origin_change_handler, false);
				});
				const selected = document.querySelector("input[name='allocation_origin']:checked");
				stash_alloc_origin = wall.allocation.origin;
			}
		/* 施工方向 編集欄 ここまで */

		/* 重ねシロ 編集欄 ここから */
			const alloc_padding = document.querySelector(".panel_allocation__padding");
			const alloc_padding_inputs = document.querySelectorAll("input[name='allocation_padding']");
			const alloc_padding_change_handler = ()=>{
				const selected = document.querySelector("input[name='allocation_padding']:checked");
				wall.allocation.padding = selected.value;
				if(wall.allocation.padding == "left"){
					if(wall.allocation.origin == "left"){
						alloc_origin_inputs.forEach(input=>{
							input.checked = (input.value == "right");
						});
						alloc_origin_change_handler();
					}
				}else if(wall.allocation.padding == "right"){
					if(wall.allocation.origin == "right" || wall.allocation.origin == "center"){
						alloc_origin_inputs.forEach(input=>{
							input.checked = (input.value == "left");
						});
						alloc_origin_change_handler();
					}
				}
				save();
			}
			const initPanelAllocPadding = ()=>{
				alloc_padding_inputs.forEach(input=>{
					input.checked = (input.value == wall.allocation.padding);
					input.addEventListener("change", alloc_padding_change_handler, false);
				});
			}
		/* 重ねシロ 編集欄 ここまで */

		/* トンボ 編集欄 ここから */
			const alloc_tombo = document.querySelector(".editorpannel__section.panel_allocation__tombo");
			const alloc_tombo_active_inputs = document.querySelectorAll("input[name='allocation_tombo_active']");
			const alloc_tombo_type_input = document.querySelector("#allocation_tombo_type");
			const alloc_tombo_origin_input = document.querySelector("#allocation_tombo_origin");
			const alloc_tombo_position_input = document.querySelector("#allocation_tombo_position");
			const alloc_tombo_type_line = document.querySelector(".panel_allocation__tombo__type");
			const alloc_tombo_position_line = document.querySelector(".panel_allocation__tombo__position");
			const alloc_tombo_active_change_handler = ()=>{
				const selected = document.querySelector("input[name='allocation_tombo_active']:checked");
				wall.allocation.tombo.active = (selected.value=="1") ? true : false;
				toggleTomboVisibility();
			}
			const toggleTomboVisibility = ()=>{
				if(wall.allocation.tombo.type == "other"){
					alloc_tombo_position_line.style.display = "flex";
				}else{
					alloc_tombo_position_line.style.display = "none";
				}
				if(wall.allocation.tombo.active){
					alloc_tombo_type_line.style.display = "flex";
				}else{
					alloc_tombo_type_line.style.display = "none";
					alloc_tombo_position_line.style.display = "none";
				}
				setConstructorAutoinputText();
			}
			const initPanelAllocTombo = ()=>{
				alloc_tombo_position_input.value = (wall.allocation.tombo.position) ? wall.allocation.tombo.position : 50;
				alloc_tombo_origin_input.value = (wall.allocation.tombo.origin) ? wall.allocation.tombo.origin : "left";
				alloc_tombo_type_input.value = (wall.allocation.tombo.type) ? wall.allocation.tombo.type : "left";
				alloc_tombo_origin_input.dispatchEvent(new Event('change'));
				alloc_tombo_type_input.dispatchEvent(new Event('change'));

				alloc_tombo_active_inputs.forEach(input=>{
					if(input.value == "1" && wall.allocation.tombo.active) input.checked = true;
					if(input.value == "0" && !wall.allocation.tombo.active) input.checked = true;
					input.addEventListener("change", ()=>{
						if(input.checked){
							if(input.value == "1"){
								wall.allocation.tombo.active = true;
								wall.allocation.origin = stash_alloc_origin;
							}else{
								wall.allocation.tombo.active = false;
								wall.allocation.origin = "other";
							}
							alloc_origin_inputs.forEach(input=>{
								input.checked = (input.value == wall.allocation.origin);
							});
							setScaleFurniturePanel();
						}
						toggleTomboVisibility();
					}, false);
				});
				alloc_tombo_type_input.addEventListener("change", ()=>{
					wall.allocation.tombo.type = alloc_tombo_type_input.value;
					if(wall.allocation.origin != "other"){
						if(wall.allocation.origin != wall.allocation.tombo.type){
							wall.allocation.origin = wall.allocation.tombo.type;
							stash_alloc_origin = wall.allocation.origin;
							alloc_origin_inputs.forEach(input=>{
								input.checked = (input.value == wall.allocation.origin);
							});
							alloc_origin_change_handler();	
							setScaleFurniturePanel();
						}
					}
					if(wall.allocation.tombo.type != "other"){
						wall.allocation.tombo.active = true;
						alloc_tombo_active_inputs.forEach(input=>{
							if(input.value == "1" && wall.allocation.tombo.active) input.checked = true;
							if(input.value == "0" && !wall.allocation.tombo.active) input.checked = true;
						});
					}
					toggleTomboVisibility();
				}, false);
				alloc_tombo_origin_input.addEventListener("change", ()=>{
					wall.allocation.tombo.origin = alloc_tombo_origin_input.value;
					setConstructorAutoinputText();
				}, false);
				alloc_tombo_position_input.addEventListener("change", ()=>{
					wall.allocation.tombo.position = alloc_tombo_position_input.value*1;
					setConstructorAutoinputText();
				}, false);
			}
		/* トンボ 編集欄 ここまで */

		/* 吹き増し寸法 編集欄 ここから */
			const alloc_overpadding = document.querySelector(".panel_allocation__overpadding");
			const alloc_overpadding_horizontal_vertical = document.querySelector("input[name='allocation_overpadding_vertical']");
			const alloc_overpadding_horizontal_horizontal = document.querySelector("input[name='allocation_overpadding_horizontal']");
			const alloc_overpadding_change_handler = ()=>{
				wall.dimension.padding_vertical = alloc_overpadding_horizontal_vertical.value*1;
				wall.dimension.padding_horizontal = alloc_overpadding_horizontal_horizontal.value*1;
				setScaleFurniturePanel();
			}
			const initPanelAllocOverPadding = ()=>{
				alloc_overpadding_horizontal_vertical.value = wall.dimension.padding_vertical;
				alloc_overpadding_horizontal_horizontal.value = wall.dimension.padding_horizontal;
				alloc_overpadding_horizontal_vertical.addEventListener("change", alloc_overpadding_change_handler, false);
				alloc_overpadding_horizontal_horizontal.addEventListener("change", alloc_overpadding_change_handler, false);
			}
		/* 吹き増し寸法 編集欄 ここまで */

		/* パネルロス 編集欄 ここから */
			const alloc_panelloss = document.querySelector(".panel_allocation__panelloss");
			const initPanelLoss = ()=>{
			}
		/* パネルロス 編集欄 ここまで */

		/* ロール幅 編集欄 ここから */
			const alloc_rollwidth = document.querySelector(".panel_allocation__rollwidth");
			const alloc_rollwidth_input = document.querySelector("input[name='allocation_rollwidth']");
			const alloc_rollwidth_change_handler = ()=>{
				wall.allocation.rollwidth = alloc_rollwidth_input.value*1;
				setScaleFurniturePanel();
			}
			const initPanelAllocRollWidth = ()=>{
				alloc_rollwidth_input.value = wall.allocation.rollwidth;
				alloc_rollwidth_input.addEventListener("change", alloc_rollwidth_change_handler, false);
			}
		/* ロール幅 編集欄 ここから */

		/* 工場向け詳細情報 編集欄 ここから */
		const factory_sample_number_subfield = document.querySelector(".panel_allocation__factory__sample__number");
		const factory_color_number_subfield = document.querySelector(".panel_allocation__factory__color__number");
		const factory_color_detail_subfield = document.querySelector(".panel_allocation__factory__color__detail");
		const factory_color_display_subfield = document.querySelector(".panel_allocation__factory__color__display");
		const factory_color_other_subfield = document.querySelector(".panel_allocation__factory__color__other");
		const factory_input_data = document.querySelectorAll("input[name='factory_data']");
		const factory_input_sample = document.querySelectorAll("input[name='factory_sample']");
		const factory_input_sample_number = document.querySelector("input[name='factory_sample_number']");
		const factory_input_color = document.querySelectorAll("input[name='factory_color']");
		const factory_input_color_number = document.querySelector("input[name='factory_color_number']");
		const factory_input_color_detail = document.querySelector("input[name='factory_color_detail']");
		const factory_input_color_display = document.querySelector("input[name='factory_color_display']");
		const factory_input_color_other = document.querySelector("textarea[name='factory_color_other']");

		const factoryDetailChangeHandler = ()=>{
			const data_selected = Array.from(factory_input_data).find(d=>d.checked);
			const sample_selected = Array.from(factory_input_sample).find(d=>d.checked);
			const color_selected = Array.from(factory_input_color).find(d=>d.checked);
			if(data_selected) wall.allocation.factory_msg.data = data_selected.value;
			if(sample_selected) wall.allocation.factory_msg.sample = sample_selected.value;
			if(color_selected) wall.allocation.factory_msg.color = color_selected.value;
			wall.allocation.factory_msg.sampleno = factory_input_sample_number.value;
			wall.allocation.factory_msg.colornumber = factory_input_color_number.value;
			wall.allocation.factory_msg.colordetail = factory_input_color_detail.value;
			wall.allocation.factory_msg.colordisplay = factory_input_color_display.checked;
			wall.allocation.factory_msg.colorother = factory_input_color_other.value;
			if(wall.allocation.factory_msg.sample == "available"){
				factory_sample_number_subfield.style.display = "block";
			}else{
				factory_sample_number_subfield.style.display = "none";
			}
			if(wall.allocation.factory_msg.color == "available"){
				factory_color_number_subfield.style.display = "block";
				factory_color_detail_subfield.style.display = "block";
				factory_color_display_subfield.style.display = "block";
				factory_color_other_subfield.style.display = "block";
			}else{
				factory_color_number_subfield.style.display = "none";
				factory_color_detail_subfield.style.display = "none";
				factory_color_display_subfield.style.display = "none";
				factory_color_other_subfield.style.display = "none";
			}
			validateErrors();
			save();
		}
		const initFactoryDetails = ()=>{
			wall.allocation.factory_msg.data = wall.allocation.factory_msg?.data ? wall.allocation.factory_msg.data : "catno";
			wall.allocation.factory_msg.sample = wall.allocation.factory_msg?.sample ? wall.allocation.factory_msg.sample : "none";
			wall.allocation.factory_msg.sampleno = wall.allocation.factory_msg?.sampleno ? wall.allocation.factory_msg.sampleno : null;
			wall.allocation.factory_msg.color = wall.allocation.factory_msg?.color ? wall.allocation.factory_msg.color : "none";
			wall.allocation.factory_msg.colornumber = wall.allocation.factory_msg?.colornumber ? wall.allocation.factory_msg.colornumber : null;
			wall.allocation.factory_msg.colordetail = wall.allocation.factory_msg?.colordetail ? wall.allocation.factory_msg.colordetail : null;
			wall.allocation.factory_msg.colordisplay = wall.allocation.factory_msg?.colordisplay ? wall.allocation.factory_msg.colordisplay : false;
			wall.allocation.factory_msg.colorother = wall.allocation.factory_msg?.colorother ? wall.allocation.factory_msg.colorother : null;

			factory_input_data.forEach(d=>{
				if(d.value == wall.allocation.factory_msg.data) d.checked = true;
				d.addEventListener("change", factoryDetailChangeHandler, false);
			});
			factory_input_sample.forEach(d=>{
				if(d.value == wall.allocation.factory_msg.sample) d.checked = true;
				d.addEventListener("change", factoryDetailChangeHandler, false);
			});
			factory_input_color.forEach(d=>{
				if(d.value == wall.allocation.factory_msg.color) d.checked = true;
				d.addEventListener("change", factoryDetailChangeHandler, false);
			});
			factory_input_sample_number.value = wall.allocation.factory_msg.sampleno;
			factory_input_color_number.value = wall.allocation.factory_msg.colornumber;
			factory_input_color_detail.value = wall.allocation.factory_msg.colordetail;
			factory_input_color_display.checked = wall.allocation.factory_msg.colordisplay;
			factory_input_color_other.value = wall.allocation.factory_msg.colorother;
			factory_input_sample_number.addEventListener("change", factoryDetailChangeHandler, false);
			factory_input_color_number.addEventListener("change", factoryDetailChangeHandler, false);
			factory_input_color_detail.addEventListener("change", factoryDetailChangeHandler, false);
			factory_input_color_display.addEventListener("change", factoryDetailChangeHandler, false);
			factory_input_color_other.addEventListener("change", factoryDetailChangeHandler, false);
		}
		/* 工場向け詳細情報 編集欄 ここから */

		/* テキストメッセージ 編集欄 ここから */
		const allocation_constructor = document.querySelector(".panel_allocation__textarea--constructor");
		const allocation_constructor_autoinput = document.querySelector("#allocation_constructor_autoinput");
		const allocation_constructor_manualinput = document.querySelector("#allocation_constructor_manualinpu");
		const allocation_factory_autoinput = document.querySelector("#allocation_factory_autoinput");
		const allocation_factory_manualinput = document.querySelector("#allocation_factory_manualinput");
		const allocation_note_input = document.querySelector("#allocation_note");
		const allocation_staff_input = document.querySelector("#allocation_staff");
		const setConstructorAutoinputText = ()=>{
			if(wall.settings.material == "interior_sheet" && wall.settings.interiorsheet_method == "panel"){
				const padding_top = 50;
				const padding_bottom = calc_padding_bottom();
				const padding_horizontal = calc_padding_horizontal();
				allocation_constructor_autoinput.value = "【トンボ】\r\n";
				if(padding_top == padding_bottom == padding_horizontal){
					allocation_constructor_autoinput.value += "各パネルに対して四方50mmずつのトンボ";
				}else{
					if(padding_top != padding_bottom){
						allocation_constructor_autoinput.value += "各パネルに対して上部"+padding_top+"mm、下部"+padding_bottom+"mm、左右"+padding_horizontal+"mmずつのトンボ";
					}else{
						allocation_constructor_autoinput.value += "各パネルに対して上下"+padding_top+"mm、左右"+padding_horizontal+"mmずつのトンボ";
					}
				}
			}else{
				if(!wall.allocation.tombo.active){
					allocation_constructor_autoinput.value = "トンボ無し";
				}else{
					let text = "";
					if(wall.allocation.tombo.type == "other"){
						if(wall.allocation.tombo.origin == "left"){
							text = "左から"+wall.allocation.tombo.position+"mm、";
						}else if(wall.allocation.tombo.origin == "right"){
							text = "右から"+wall.allocation.tombo.position+"mm、";
						}
						text += "上から50mm";
					}else if(wall.allocation.tombo.type == "center"){
						if(this.alloc_rolls.length%2 == 0){
							const p2 = this.alloc_rolls.length/2;
							const p1 = p2-1;
							text = this.alloc_rolls[p1]?.label+this.alloc_rolls[p2]?.label+"間 上から50mm";
						}else{
							const p1 = Math.floor(this.alloc_rolls.length/2);
							text = this.alloc_rolls[p1].label+"中心位置 上から50mm";
						}
					}else{
						if(wall.allocation.tombo.type == "left"){
							let p = "左から50mm、";
							if(this.is_alloc_panel){
								p = "左から"+wall.dimension.padding_horizontal+"mm、"
							}
							text = "分割1左上 "+p+"上から50mm";
						}else if(wall.allocation.tombo.type == "right"){
							let p = "右から50mm、";
							if(this.is_alloc_panel){
								p = "右から"+wall.dimension.padding_horizontal+"mm、"
							}
							text = "分割1右上 "+p+"上から50mm";
						}
					}

					allocation_constructor_autoinput.value = "トンボ有り ["+text+"]\r\n";
					allocation_constructor_autoinput.value += "トンボ位置を起点に施工をお願いします。";
				}
			}
			panelTextMessagesChangeHandler();
		}
		const setFactoryAutoinputText = ()=>{
			let scaleText = "";
			let positionText = "";
			let repeatText = "";
			let fillText = "";
			let margin = {
				top:(wall.wallpaper.position.y > 0),
				right:(wall.wallpaper.position.x + wall.wallpaper.dimension.width < this.alloc_width),
				bottom:(wall.wallpaper.position.y + wall.wallpaper.dimension.height < this.alloc_height),
				left:(wall.wallpaper.position.x > 0)
			};
			if(wall.wallpaper.allow_variable_scale){
				scaleText = "H"+wall.wallpaper.dimension.height+"mm、W"+wall.wallpaper.dimension.width+"mmに変倍";
				if(this.alloc_width <= wall.wallpaper.dimension.width || this.alloc_height <= wall.wallpaper.dimension.height){
					scaleText += "拡大";
				}else{
					scaleText += "縮小";
				}
			}else{
				if(wall.wallpaper.dimension.height == wall.wallpaper.dimension.natural_height){
					scaleText = "標準サイズ (H"+wall.wallpaper.dimension.height+") のまま";
				}else{
					scaleText = "H"+wall.wallpaper.dimension.height+"mmに合わせて等倍";
					if(this.alloc_height <= wall.wallpaper.dimension.height){
						scaleText += "拡大";
					}else{
						scaleText += "縮小";
					}
				}
			}
			if(wall.wallpaper.reverseRepeat){
				scaleText += "、図柄を左右反転";
			}
			if(wall.allocation.origin == "left"){
				if(wall.wallpaper.position.x == 0){
					const v = (wall.wallpaper.position.y < 0) ? "上" : "下";
					positionText = "分割1左上から"+v+"に"+Math.abs(wall.wallpaper.position.y)+"mm移動";
					if(wall.wallpaper.position.y + wall.wallpaper.dimension.height == this.alloc_height){
						positionText = "分割1左下合わせ";
					}
					if(wall.wallpaper.position.y == 0){
						positionText = "分割1左上合わせ";
					}
				}else{
					const v = (wall.wallpaper.position.y < 0) ? "上" : "下";
					const h = (wall.wallpaper.position.x < 0) ? "左" : "右";
					positionText = "分割1左上から"+h+"に"+Math.abs(wall.wallpaper.position.x)+"mm移動";
					if(wall.wallpaper.position.y != 0){
						positionText += "、"+v+"に"+Math.abs(wall.wallpaper.position.y)+"mm移動";
					}
				}
			}else{
				if(wall.wallpaper.position.x + wall.wallpaper.dimension.width == this.alloc_width){
					const v = (wall.wallpaper.position.y < 0) ? "上" : "下";
					positionText = "分割1右上から"+v+"に"+Math.abs(wall.wallpaper.position.y)+"mm移動";
					if(wall.wallpaper.position.y + wall.wallpaper.dimension.height == this.alloc_height){
						positionText = "分割1右下合わせ";
					}
					if(wall.wallpaper.position.y == 0){
						positionText = "分割1右上合わせ";
					}
				}else{	
					const posx = this.alloc_width - (wall.wallpaper.position.x + wall.wallpaper.dimension.width);
					const h = (posx < 0) ? "右" : "左";
					const v = (wall.wallpaper.position.y < 0) ? "上" : "下";
					positionText = "分割1右上から"+h+"に"+Math.abs(posx)+"mm移動";
					if(wall.wallpaper.position.y != 0){
						positionText += "、"+v+"に"+Math.abs(wall.wallpaper.position.y)+"mm移動";
					}
				}
			}
			if(wall.wallpaper.repeat == "horizontal"){
				if(margin.left || margin.right){
					const l = margin.left ? "左" : "";
					const r = margin.right ? "右" : "";
					repeatText = l+r+"にリピート"
				}
				if(margin.top && wall.wallpaper.fillactive.top && wall.wallpaper.fillcolor.top){
					fillText += "。上部塗り足し";
				}
				if(margin.bottom && wall.wallpaper.fillactive.bottom && wall.wallpaper.fillcolor.bottom){
					fillText += "。下部塗り足し";
				}
			}else if(wall.wallpaper.repeat == "vertical"){
				if(margin.top || margin.bottom){
					const t = margin.top ? "上" : "";
					const b = margin.bottom ? "下" : "";
					repeatText = t+b+"にリピート"
				}
				if(margin.left && wall.wallpaper.fillactive.left && wall.wallpaper.fillcolor.left){
					fillText += "。左部塗り足し";
				}
				if(margin.right && wall.wallpaper.fillactive.right && wall.wallpaper.fillcolor.right){
					fillText += "。右部塗り足し";
				}
			}else if(wall.wallpaper.repeat == "repeat"){
				if(margin.left || margin.right || margin.top || margin.bottom){
					const l = margin.left ? "左" : "";
					const r = margin.right ? "右" : "";
					const t = margin.top ? "上" : "";
					const b = margin.bottom ? "下" : "";
					repeatText = t+b+l+r+"にリピート"
				}
			}

			allocation_factory_autoinput.value = "";
			if(wall.settings.material == "wallpanel"){
				allocation_factory_autoinput.value = "上下左右にロス150mmあり。\r\n";	
			}
			allocation_factory_autoinput.value += scaleText;	
			if(positionText) allocation_factory_autoinput.value += " → "+positionText;
			if(repeatText) allocation_factory_autoinput.value += " → "+repeatText;
			if(fillText) allocation_factory_autoinput.value += fillText+"。";
			panelTextMessagesChangeHandler();
		}
		const panelTextMessagesChangeHandler = ()=>{
			wall.allocation.constructor_msg.autotext = allocation_constructor_autoinput.value;
			wall.allocation.constructor_msg.manualtext = allocation_constructor_manualinput.value;
			wall.allocation.factory_msg.autotext = allocation_factory_autoinput.value;
			wall.allocation.factory_msg.manualtext = allocation_factory_manualinput.value;
			wall.allocation.notice_msg = allocation_note_input.value;
			wall.settings.staff = allocation_staff_input.value;
			validateErrors();
			save();
		}
		const initPanelTextMessages = ()=>{
			allocation_constructor_autoinput.addEventListener("change", panelTextMessagesChangeHandler, false);
			allocation_constructor_manualinput.addEventListener("change", panelTextMessagesChangeHandler, false);
			allocation_factory_autoinput.addEventListener("change", panelTextMessagesChangeHandler, false);
			allocation_factory_manualinput.addEventListener("change", panelTextMessagesChangeHandler, false);
			allocation_note_input.addEventListener("change", panelTextMessagesChangeHandler, false);
			allocation_staff_input.addEventListener("change", panelTextMessagesChangeHandler, false);

			if(wall.allocation.constructor_msg.manualtext){
				allocation_constructor_manualinput.value = wall.allocation.constructor_msg.manualtext;	
			}
			if(wall.allocation.factory_msg.manualtext){
				allocation_factory_manualinput.value = wall.allocation.factory_msg.manualtext;	
			}
			if(wall.allocation.notice_msg){
				allocation_note_input.value = wall.allocation.notice_msg;	
			}
			if(wall.settings.staff){
				allocation_staff_input.value = wall.settings.staff;	
			}
		}
		const restoreStashedAutoText = (constructorAutotext = false, factoryAutotext = false)=>{
			if(constructorAutotext){
				allocation_constructor_autoinput.value = constructorAutotext;
				wall.allocation.constructor_msg.autotext = constructorAutotext;
				save();
			}
			if(factoryAutotext){
				allocation_factory_autoinput.value = factoryAutotext;
				wall.allocation.factory_msg.autotext = factoryAutotext;
				save();
			}
		}
		/* テキストメッセージ 編集欄 ここまで */

		/* 図面確認ボタンとエラー表記 ここから */
		const errorLabels = Array.from(document.querySelectorAll(".editorpannel__section__error"));
		const reviewbtn = editor_root.querySelector(".reviewbtn");
		const validateErrors = ()=>{
			this.currentErrors = [];

			//壁紙サイズがtolerance内に収まっているか
			if(wall.wallpaper.allow_variable_scale){
				// 変倍拡大
				if(
					wall.wallpaper.tolerance.variable.width.min > wall.wallpaper.dimension.width || 
					wall.wallpaper.tolerance.variable.width.max < wall.wallpaper.dimension.width || 
					wall.wallpaper.tolerance.variable.height.min > wall.wallpaper.dimension.height || 
					wall.wallpaper.tolerance.variable.height.max < wall.wallpaper.dimension.height
				){
					this.currentErrors.push("design_variable_size_invalid");
				}
			}else{
				// 等倍拡大
				if(
					wall.wallpaper.tolerance.actual.min > wall.wallpaper.dimension.height || 
					wall.wallpaper.tolerance.actual.max < wall.wallpaper.dimension.height
				){
					this.currentErrors.push("design_actual_size_invalid");
				}
			}

			//wallpanel パネルサイズの最小・最大に収まっているか
			if(wall.settings.material == "wallpanel"){
				const long = Math.max(wall.dimension.width, wall.dimension.height);
				const short = Math.min(wall.dimension.width, wall.dimension.height);
				if(long > 3300 || short > 1000){
					this.currentErrors.push("wallpanel_maxsize_invalid");
				}
			}

			//INTERIOR SHEET パネルサイズの合計の検証
			if(wall.settings.material == "interior_sheet" && wall.settings.interiorsheet_method == "panel"){
				if(wall.panels){
					if(Array.isArray(wall.panels.width)){
						if(wall.panels.width.length > 0){ // panels.widthに幅配列が入っていたら
							let widthArray = (wall.panels.origin=="right") ? wall.panels.width.toReversed() : wall.panels.width;
							if(widthArray.reduce((acc, current) => acc + current) != wall.dimension.width){ // panels.widthの合計が壁面幅と一致しなかったら
								this.currentErrors.push("panel_width_total_invalid");
							}
							const padding_horizontal = calc_padding_horizontal();
							if(widthArray.some(w=>(w+padding_horizontal*2)>1260)) this.currentErrors.push("panel_roll_width_invalid");
						}else{
							this.currentErrors.push("panel_width_total_invalid");
						}
					}else{
						this.currentErrors.push("panel_width_total_invalid");
					}
				}
			}

			//INTERIOR SHEET ロール幅の検証
			if(wall.settings.material == "interior_sheet" && wall.settings.interiorsheet_method == "normal"){
				if(wall.allocation.rollwidth > 1260) this.currentErrors.push("allocation_rollwidth_invalid");
			}

			//工場向け指示情報の検証
			if(wall.allocation.factory_msg.sample == "available"){
				if(!wall.allocation.factory_msg.sampleno) this.currentErrors.push("factory_samplenumber_invalid");
			}
			if(wall.allocation.factory_msg.color == "available"){
				if(!wall.allocation.factory_msg.colornumber) this.currentErrors.push("factory_colornumber_invalid");
				if(!wall.allocation.factory_msg.colordetail) this.currentErrors.push("factory_colordetail_invalid");
			}

			//担当者名の検証
			if(!wall.settings.staff){
				this.currentErrors.push("staffname_invalid");
			}

			errorLabels.forEach(label=>{
				//label.style.display = "none";
				label.classList.remove("show");
				if(this.currentErrors.some(error=>error==label.id)){
					//label.style.display = "block";
					label.classList.add("show");
				}
			});

			wall.errors = this.currentErrors;
			save();
			if(this.currentErrors.length > 0){
				reviewbtn.classList.add("hasError");
			}else{
				reviewbtn.classList.remove("hasError");
			}
		}
		const reviewbtn_button = editor_root.querySelector(".reviewbtn .reviewbtn__button");
		const initReviewbtnEvent = ()=>{
			reviewbtn_button.addEventListener("click", (e)=>{
				const popuphandler = window.open(reviewbtn_button.href, "review", "width="+window.screen.width+",height="+Math.min(1280, window.screen.height));
				if(!popuphandler){
					window.location.href = reviewbtn_button.href;
				}
				e.stopPropagation();
				e.preventDefault();
				return false;
			}, false);
		}
		/* 図面確認ボタンとエラー表記 ここまで */


		/* そのほかのツール ここから */
		const setDesignScale = ()=>{
			const designscale = editor_root.querySelector(".designscale");
			const designscale_horizontal = editor_root.querySelector(".designscale__horizontal");
			const designscale_vertical = editor_root.querySelector(".designscale__vertical");
			const designscale_horizontal_label = editor_root.querySelector(".designscale__horizontal__label");
			const designscale_vertical_label = editor_root.querySelector(".designscale__vertical__label");

			let outboundV = true;
			let positionV = 0;
			let outboundH = true;
			let positionH = 0;
			if(wall.allocation.origin == "left"){
				outboundV = wall.wallpaper.position.y <= 0;
				positionV = Math.abs(wall.wallpaper.position.y);
				outboundH = wall.wallpaper.position.x <= 0;
				positionH = Math.abs(wall.wallpaper.position.x);
				if(outboundH){
					designscale_horizontal.style.top = "50%";
					designscale_horizontal.style.left = "0px";
					designscale_horizontal.style.bottom = "auto";
					designscale_horizontal.style.right = "auto";
				}else{
					designscale_horizontal.style.top = "50%";
					designscale_horizontal.style.right = "100%";
					designscale_horizontal.style.bottom = "auto";
					designscale_horizontal.style.left = "auto";
				}
			}else{
				outboundV = wall.wallpaper.position.y <= 0;
				positionV = Math.abs(wall.wallpaper.position.y);
				const posx = this.alloc_width - (wall.wallpaper.position.x + wall.wallpaper.dimension.width);
				outboundH = posx <= 0;
				positionH = Math.abs(posx);
				if(outboundH){
					designscale_horizontal.style.top = "50%";
					designscale_horizontal.style.right = "0px";
					designscale_horizontal.style.bottom = "auto";
					designscale_horizontal.style.left = "auto";
				}else{
					designscale_horizontal.style.top = "50%";
					designscale_horizontal.style.left = "100%";
					designscale_horizontal.style.bottom = "auto";
					designscale_horizontal.style.right = "auto";
				}
			}
			designscale_horizontal.style.width = "calc("+positionH+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+")";
			designscale_vertical.style.height = "calc("+positionV+"px * var(--zoom) * var(--containmentScale) * "+window.settings.grandscale+")";
			if(outboundV){
				designscale_vertical.style.top = "0px";
				designscale_vertical.style.left = "calc(50% - 1px)";
				designscale_vertical.style.bottom = "auto"
				designscale_vertical.style.right = "auto";
			}else{
				designscale_vertical.style.bottom = "100%";
				designscale_vertical.style.left = "calc(50% - 1px)";
				designscale_vertical.style.top = "auto";
				designscale_vertical.style.right = "auto";
			}
			designscale_horizontal_label.innerText = positionH;
			designscale_vertical_label.innerText = positionV;
			if(positionH == 0){
				designscale_horizontal.style.display = "none";
			}
			if(positionV == 0){
				designscale_vertical.style.display = "none";
			}
		}
		const setAutoZoom = (mode = null)=>{
			requestAnimationFrame(()=>{requestAnimationFrame(()=>{
				let containmentTarget = canvas;
				const zoom = 2;
				const storedScale = root.style.getPropertyValue('--containmentScale');
				let containmentScale = storedScale ? storedScale*1 : 1;
				const wallWidth = parseInt(root.style.getPropertyValue('--wallWidth'), 10);
				const wallHeight = parseInt(root.style.getPropertyValue('--wallHeight'), 10);
				const allocWidth = parseInt(root.style.getPropertyValue('--allocWidth'), 10);
				const allocHeight = this.alloc_height;

				//出てるスケール（入隅やパネル、ロール巾やパネル合計など）に応じてclearanceを変化させる仕組みを作る

				const containmentBoundWidth = editor_root.offsetWidth;
				const containmentBoundHeight = editor_root.offsetHeight;
				

				if(mode == "new_wall"){
					// 新規壁面作成時 プレビュー表示
					containmentTarget = bound;
					let topClearance = 70;
					let bottomClearance = 10;
					let leftClearance = 10;
					let rightClearance = 70;

					if(wall.settings.material == "interior_sheet" && wall.settings.interiorsheet_method == "panel"){
						topClearance += 40;
					}else if(wall.corner.length > 0){
						topClearance += 60;
					}

					const width = (wall.dimension.width/10) * zoom;
					const height = (wall.dimension.height/10) * zoom;
					const containmentAreaWidth = containmentBoundWidth-leftClearance-rightClearance;
					const containmentAreaHeight = containmentBoundHeight-topClearance-bottomClearance;
					if(width > containmentAreaWidth){
						containmentScale = containmentAreaWidth/width;
					}
					if(height*containmentScale > containmentAreaHeight){
						containmentScale = containmentAreaHeight/height;
					}
					root.style.setProperty('--containmentScale',containmentScale);

					let offsetY = (topClearance-bottomClearance)/2;
					let offsetX = (leftClearance-rightClearance)/2;

					bound.style.marginTop = offsetY+"px";
					bound.style.marginLeft = offsetX+"px";
				}else{
					//図面確認画面
					let topClearance = 65;
					let bottomClearance = 70;
					let leftClearance = 65;
					let rightClearance = 65;
					let width = Math.max(wallWidth, allocWidth)/10;
					let height = Math.max(wallHeight, allocHeight)/10;

					if(wall.settings.material == "interior_sheet" && wall.settings.interiorsheet_method == "panel"){
						topClearance += 40;
						bottomClearance += 40;
					}else if(wall.corner.length > 0){
						topClearance += 60;
					}
					
					let ghostDesignLeftOverflow = Math.abs(Math.min(0, ghostDesign.offsetLeft));
					let ghostDesignRightOverflow = Math.max(0, ghostDesign.offsetLeft+ghostDesign.offsetWidth-bound.offsetWidth);
					let ghostDesignTopOverflow =  Math.abs(Math.min(0, ghostDesign.offsetTop));
					let ghostDesignBottomOverflow = Math.max(0, ghostDesign.offsetTop+ghostDesign.offsetHeight-bound.offsetHeight);

					const scalewidth = (width+ghostDesignLeftOverflow+ghostDesignRightOverflow) * zoom;
					const scaleheight = (height+ghostDesignTopOverflow+ghostDesignBottomOverflow) * zoom;
					const containmentAreaWidth = containmentBoundWidth-leftClearance-rightClearance;
					const containmentAreaHeight = containmentBoundHeight-topClearance-bottomClearance;
					if(scalewidth > containmentAreaWidth){
						containmentScale = containmentAreaWidth/scalewidth;
					}
					if(scaleheight*containmentScale > containmentAreaHeight){
						containmentScale = containmentAreaHeight/scaleheight;
					}

					const padding_top = parseInt(root.style.getPropertyValue('--paddingTop'), 10);
					const padding_bottom = parseInt(root.style.getPropertyValue('--paddingBottom'), 10);
					const padding_horizontal = parseInt(root.style.getPropertyValue('--paddingHorizontal'), 10);

					let allocOffsetY = (padding_top-padding_bottom)/2/10 * containmentScale;
					let allocOffsetX = (allocWidth-wallWidth - padding_horizontal*2)/10 * containmentScale;
					if(wall.allocation.origin == "left"){
						allocOffsetX = allocOffsetX*-1;
					}
					let ghostOffsetY = (ghostDesignTopOverflow-ghostDesignBottomOverflow) * containmentScale;
					let ghostOffsetX = (ghostDesignLeftOverflow-ghostDesignRightOverflow) * containmentScale;
					let offsetY = (topClearance-bottomClearance)/2 + allocOffsetY + ghostOffsetY;
					let offsetX = (leftClearance-rightClearance) + allocOffsetX + ghostOffsetX;
					

					root.style.setProperty('--zoom',zoom);
					root.style.setProperty('--containmentScale',containmentScale);
					root.style.setProperty('--allocsheet_offsetX',offsetX+"px");
					root.style.setProperty('--allocsheet_offsetY',offsetY+"px");
				}
			})});
		}
		const adaptiveZoom = ()=>{
			requestAnimationFrame(()=>{requestAnimationFrame(()=>{
				const bound = document.querySelector(".editor");
				const scale_vertical = editor_root.querySelector(".scale_vertical");
				const scale_horizontal = editor_root.querySelector(".scale_horizontal");
				const boundWidth = bound.offsetWidth - 140 - 120;
				const boundHeight = bound.offsetHeight - 114 - 95;
				const targetWidth = instance.offsetWidth + scale_vertical.offsetWidth;
				const targetHeight = instance.offsetHeight + scale_horizontal.offsetHeight;
				const width_ratio = boundWidth / targetWidth;
				const height_ratio = boundHeight / targetHeight;
				const adjust_target_ratio = Math.min(width_ratio, height_ratio);

				const adapted_zoom_lebel = zoomTable.reduce((closestIndex, currentValue, currentIndex)=>{
					return (Math.abs(currentValue - adjust_target_ratio) < Math.abs(zoomTable[closestIndex] - adjust_target_ratio) ? currentIndex : closestIndex);
				}, 0);
				ctrl.editor.zoom = adapted_zoom_lebel;
				setZoom();
			})});
		}
		const containDimension = (width=0, height=0, assignData=false)=>{
			const dimension = {
				width: width,
				height: height
			};
			// 最小・最大に収める処理は一旦OFF
			if(wall.wallpaper.allow_variable_scale){
				// 変倍拡大
				// dimension.width = Math.max(wall.wallpaper.tolerance.variable.width.min, Math.min(wall.wallpaper.tolerance.variable.width.max, width));
				// dimension.height = Math.max(wall.wallpaper.tolerance.variable.height.min, Math.min(wall.wallpaper.tolerance.variable.height.max, height));
			}else{
				// 等倍拡大
				// dimension.height = Math.max(wall.wallpaper.tolerance.actual.min, Math.min(wall.wallpaper.tolerance.actual.max, height));
				// dimension.width = (wall.wallpaper.dimension.natural_width/wall.wallpaper.dimension.natural_height) * dimension.height;
			}
			if(!wall.wallpaper.allow_variable_scale){
				dimension.width = dimension.height * (wall.wallpaper.dimension.natural_width/wall.wallpaper.dimension.natural_height);
			}	
			dimension.height = Math.round(dimension.height);
			dimension.width = Math.round(dimension.width);
			if(assignData){
				wall.wallpaper.dimension.width = dimension.width;
				wall.wallpaper.dimension.height = dimension.height;
			}
			return dimension;
		}

		const project_id = document.body.getAttribute("data-pid");
		const project_slug = document.body.getAttribute("data-slug");
		let firstsave = true;
		const save = debounce(750, ()=>{
			if(firstsave){
				firstsave = false;
				return;
			}
			const savepromise = window.DataControll.save(project_id, true);
			editor.classList.remove("save_failed");
			editor.classList.remove("save_done");
			editor.classList.add("saving");
			savepromise.then(result => {
				// console.log("success");
				// console.log(result);
				editor.classList.remove("saving");
				editor.classList.add("save_done");
			});
			savepromise.catch(result => {
				// console.log("error");
				// console.log(result);
				editor.classList.remove("saving");
				editor.classList.add("save_failed");
			});
		});
		/* そのほかのツール ここまで */

		isInitialized = true;
		this.InitAsEditor = ()=>{
			this.isEditor = true;

			const constructorAutoinputHaven = wall.allocation.constructor_msg.autotext;
			const factoryAutoinputHaven = wall.allocation.factory_msg.autotext;
			initPanelTextMessages();

			initToggleVisibleButtonEvents();
			initEditorDragMoveEvents();
			initChangeZoomEvents();
			initRepeatSetting();
			initGrips();
			initInstanceDragMoveEvent();
			initToggleInstanceActiveEvent();
			initKeyMoveEvents();
			initEditorPannels();

			initPanellWallname();
			initPanelWallDimension();
			initPanelInteriorSheetMethod();
			initPanelCorners();
			initPanelPanels();
			initPanelFurniture();
			initPanelWallpaper();
			initPanelLayout();
			initPanelAllocPadding();
			initPanelAllocTombo();
			initPanelAllocOrigin();
			initPanelAllocOverPadding();
			initPanelAllocRollWidth();
			initReviewbtnEvent();

			update_params();
			applyFillColorSettings();
			setScaleFurniturePanel();
			panelSettingToggleVisibility();
			toggleTomboVisibility();
			// mediatype_panelmethod_change_handler();
			initFactoryDetails();
			factoryDetailChangeHandler();

			restoreStashedAutoText(constructorAutoinputHaven, factoryAutoinputHaven);
			adaptiveZoom();
		}

		this.InitAsPreview = ()=>{
			this.isEditor = false;

			initRepeatSetting();
			update_params();
			applyFillColorSettings();
			setScaleFurniturePanel();
			setDesignScale();

			setAutoZoom();
		}
		this.InitAsNewWall = ()=>{
			this.isEditor = false;

			update_params();
			setScaleFurniturePanel();
			editor.setAttribute("data-alloc-visible", false);

			setAutoZoom("new_wall");
		}
	}
}