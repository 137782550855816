export default class Factory{
	constructor(){
	}

	rounding(inputs){
		inputs.forEach((ni)=>{
			ni.addEventListener("change", ()=>{
				ni.value = Math.round(ni.value);
			});
		});
	}

	cornerItem(number=0){
		const dom = document.createElement("div");
		dom.classList.add("editorpannel__section__repeater__entries__item");
		dom.innerHTML = `
	<div class="editorpannel__section__repeater__entries__item__body panel_wall__corners__item">
		<div class="panel_wall__corners__item__type">
			<div class="editorpannel__section__select">
				<select name="wall_corner_${number}_type" id="wall_corner_${number}_type" tabindex="-1">
					<option value="inner">入隅</option>
					<option value="outer">出隅</option>
				</select>
				<label for="wall_corner_${number}_type">入隅</label>
			</div>
		</div>
		<div class="panel_wall__corners__item__origin">
			<div class="editorpannel__section__select">
				<select name="wall_corner_${number}_origin" id="wall_corner_${number}_origin" tabindex="-1">
					<option value="left">左から</option>
					<option value="right">右から</option>
				</select>
				<label for="wall_corner_${number}_origin">左から</label>
			</div>
		</div>
		<div class="panel_wall__corners__item__size">
			<div class="editorpannel__section__input">
				<input type="number" id="wall_corner_${number}_size" name="wall_corner_${number}_size" value="0">
			</div>
		</div>
		<div class="panel_wall__corners__item__unit">mm</div>
	</div>
	<button class="editorpannel__section__repeater__entries__item__del" tabindex="-1"></button>
`;
		
		const numberInputs = dom.querySelectorAll("input[type='number']");
		if(numberInputs) this.rounding(numberInputs);

		return dom;
	}

	panelItem(number=0){
		const dom = document.createElement("div");
		dom.setAttribute("data-number", number);
		dom.classList.add("editorpannel__section__repeater__entries__item");
		dom.innerHTML = `
	<div class="editorpannel__section__repeater__entries__item__body panel_wall__panel__item">
		<div class="editorpannel__section__select panel_wall__panel__item__origin">
			<select name="wall_panel_${number}_origin" id="wall_panel_${number}_origin" tabindex="-1">
				<option value="left" selected>左から</option>
				<option value="right">右から</option>
			</select>
			<label for="wall_panel_${number}_origin">左から</label>
		</div>
		<div class="panel_wall__panel__item__width">
			<div class="panel_wall__panel__item__label">幅</div>
			<div class="editorpannel__section__input">
				<input type="number" id="wall_panel_${number}_width" name="wall_panel_${number}_width" value="0">
			</div>
			<div class="panel_wall__panel__item__unit">mm</div>
		</div>
		<div class="panel_wall__panel__item__height">
			<div class="panel_wall__panel__item__label">高さ</div>
			<div class="editorpannel__section__input inactive">
				<input type="number" id="wall_panel_${number}_height" name="wall_panel_${number}_height" value="0" tabindex="-1">
			</div>
			<div class="panel_wall__panel__item__unit">mm</div>
		</div>
		<div class="panel_wall__panel__item__duplicate">
			<button class="panel_wall__panel__item__duplicate__btn">複製</button>
		</div>
	</div>
	<button class="editorpannel__section__repeater__entries__item__del" tabindex="-1"></button>
`;
		
		const numberInputs = dom.querySelectorAll("input[type='number']");
		if(numberInputs) this.rounding(numberInputs);

		return dom;
	}

	furnitureItem(number=0){
		const dom = document.createElement("div");
		dom.classList.add("editorpannel__section__repeater__entries__item");
		dom.innerHTML = `
	<div class="editorpannel__section__repeater__entries__item__body panel_wall__furniture__item">
		<div class="panel_wall__furniture__item__name">
			<label for="wall_furniture_${number}_name" class="panel_wall__furniture__item__label">名称</label>
			<div class="editorpannel__section__input">
				<input type="text" id="wall_furniture_${number}_name" name="wall_furniture_${number}_name" value="カウンター">
			</div>
		</div>
		<div class="panel_wall__furniture__item__width">
			<label for="wall_furniture_${number}_width" class="panel_wall__furniture__item__label">幅</label>
			<div class="editorpannel__section__input">
				<input type="number" id="wall_furniture_${number}_width" name="wall_furniture_${number}_width" value="2200">
			</div>
			<div class="panel_wall__furniture__item__unit">mm</div>
		</div>
		<div class="panel_wall__furniture__item__height">
			<label for="wall_furniture_${number}_height" class="panel_wall__furniture__item__label">高さ</label>
			<div class="editorpannel__section__input">
				<input type="number" id="wall_furniture_${number}_height" name="wall_furniture_${number}_height" value="1000">
			</div>
			<div class="panel_wall__furniture__item__unit">mm</div>
		</div>
		<div class="panel_wall__furniture__item__horizontal">
			<div class="editorpannel__section__select panel_wall__furniture__item__select">
				<select name="wall_furniture_${number}_origX" id="wall_furniture_${number}_origX" tabindex="-1">
					<option value="left" selected>左から</option>
					<option value="right">右から</option>
				</select>
				<label for="wall_furniture_${number}_origX">左から</label>
			</div>
			<div class="editorpannel__section__input">
				<input type="number" id="wall_furniture_${number}_posX" name="wall_furniture_${number}_posX" value="800">
			</div>
			<div class="panel_wall__furniture__item__unit">mm</div>
		</div>
		<div class="panel_wall__furniture__item__vertical">
			<div class="editorpannel__section__select panel_wall__furniture__item__select">
				<select name="wall_furniture_${number}_origY" id="wall_furniture_${number}_origY" tabindex="-1">
					<option value="top">上から</option>
					<option value="bottom" selected>下から</option>
				</select>
				<label for="wall_furniture_${number}_origY">下から</label>
			</div>
			<div class="editorpannel__section__input">
				<input type="number" id="wall_furniture_${number}_posY" name="wall_furniture_${number}_posY" value="0">
			</div>
			<div class="panel_wall__furniture__item__unit">mm</div>
		</div>
	</div>
	<button class="editorpannel__section__repeater__entries__item__del" tabindex="-1"></button>
`;
		
		const numberInputs = dom.querySelectorAll("input[type='number']");
		if(numberInputs) this.rounding(numberInputs);

		return dom;
	}

	newWallCornerItem(number=0){
		const dom = document.createElement("div");
		dom.classList.add("modal__repeater__entries__item");
		dom.innerHTML = `
	<div class="modal__repeater__entries__item__body modal__newwall__corner__item">
		<div class="modal__newwall__corner__item__type">
			<div class="modal__selector">
				<select name="wall_corner_${number}_type" id="wall_corner_${number}_type" tabindex="-1">
					<option value="inner">入隅</option>
					<option value="outer">出隅</option>
				</select>
				<label for="wall_corner_${number}_type">入隅</label>
			</div>
		</div>
		<div class="modal__newwall__corner__item__origin">
			<div class="modal__selector">
				<select name="wall_corner_${number}_origin" id="wall_corner_${number}_origin" tabindex="-1">
					<option value="left">左から</option>
					<option value="right">右から</option>
				</select>
				<label for="wall_corner_${number}_origin">左から</label>
			</div>
		</div>
		<div class="modal__newwall__corner__item__size">
			<div class="editorpannel__section__input">
				<input type="number" id="wall_corner_${number}_size" name="wall_corner_${number}_size" value="0" tabindex="-1">
			</div>
		</div>
		<div class="modal__newwall__corner__item__unit">mm</div>
	</div>
	<button class="modal__repeater__entries__item__del" tabindex="-1"></button>
`;
		
		const numberInputs = dom.querySelectorAll("input[type='number']");
		if(numberInputs) this.rounding(numberInputs);

		return dom;
	}

	newWallPanelItem(number=0){
		const dom = document.createElement("div");
		dom.setAttribute("data-number", number);
		dom.classList.add("modal__repeater__entries__item");
		dom.innerHTML = `
	<div class="modal__repeater__entries__item__body modal__newwall__panels__item">
		<div class="modal__selector modal__newwall__panels__item__origin">
			<select name="wall_panel_${number}_origin" id="wall_panel_${number}_origin" tabindex="-1">
				<option value="left" selected="">左から</option>
				<option value="right">右から</option>
			</select>
			<label for="wall_panel_${number}_origin">左から</label>
		</div>
		<div class="modal__newwall__panels__item__width">
			<div class="modal__newwall__panels__item__label">幅</div>
			<div class="editorpannel__section__input">
				<input type="number" id="wall_panel_${number}_width" name="wall_panel_${number}_width" value="0" tabindex="-1">
			</div>
			<div class="modal__newwall__panels__item__unit">mm</div>
		</div>
		<div class="modal__newwall__panels__item__height">
			<div class="modal__newwall__panels__item__label">高さ</div>
			<div class="editorpannel__section__input inactive">
				<input type="number" id="wall_panel_${number}_height" name="wall_panel_${number}_height" value="0" tabindex="-1">
			</div>
			<div class="modal__newwall__panels__item__unit">mm</div>
		</div>
		<div class="modal__newwall__panels__item__duplicate">
			<button class="modal__newwall__panels__item__duplicate__btn">複製</button>
		</div>
	</div>
	<button class="modal__repeater__entries__item__del" tabindex="-1"></button>
`;
		
		const numberInputs = dom.querySelectorAll("input[type='number']");
		if(numberInputs) this.rounding(numberInputs);

		return dom;
	}

	newWallFurnitureItem(number=0){
		const dom = document.createElement("div");
		dom.classList.add("modal__repeater__entries__item");
		dom.innerHTML = `
	<div class="modal__repeater__entries__item__body modal__newwall__furniture__item">
		<div class="modal__newwall__furniture__item__name">
			<label for="wall_furniture_${number}_name" class="modal__newwall__furniture__item__label">名称</label>
			<div class="editorpannel__section__input">
				<input type="text" id="wall_furniture_${number}_name" name="wall_furniture_${number}_name" value="" tabindex="-1">
			</div>
		</div>
		<div class="modal__newwall__furniture__item__width">
			<label for="wall_furniture_${number}_width" class="modal__newwall__furniture__item__label">幅</label>
			<div class="editorpannel__section__input">
				<input type="number" id="wall_furniture_${number}_width" name="wall_furniture_${number}_width" value="0" tabindex="-1">
			</div>
			<div class="modal__newwall__furniture__item__unit">mm</div>
		</div>
		<div class="modal__newwall__furniture__item__height">
			<label for="wall_furniture_${number}_height" class="modal__newwall__furniture__item__label">高さ</label>
			<div class="editorpannel__section__input">
				<input type="number" id="wall_furniture_${number}_height" name="wall_furniture_${number}_height" value="0" tabindex="-1">
			</div>
			<div class="modal__newwall__furniture__item__unit">mm</div>
		</div>
		<div class="modal__newwall__furniture__item__horizontal">
			<div class="modal__selector modal__newwall__furniture__item__select">
				<select name="wall_furniture_${number}_origX" id="wall_furniture_${number}_origX" tabindex="-1">
					<option value="left" selected="">左から</option>
					<option value="right">右から</option>
				</select>
				<label for="wall_furniture_${number}_origX">左から</label>
			</div>
			<div class="editorpannel__section__input">
				<input type="number" id="wall_furniture_${number}_posX" name="wall_furniture_${number}_posX" value="0" tabindex="-1">
			</div>
			<div class="modal__newwall__furniture__item__unit">mm</div>
		</div>
		<div class="modal__newwall__furniture__item__vertical">
			<div class="modal__selector modal__newwall__furniture__item__select">
				<select name="wall_furniture_${number}_origY" id="wall_furniture_${number}_origY" tabindex="-1">
					<option value="top">上から</option>
					<option value="bottom" selected="">下から</option>
				</select>
				<label for="wall_furniture_${number}_origY">下から</label>
			</div>
			<div class="editorpannel__section__input">
				<input type="number" id="wall_furniture_${number}_posY" name="wall_furniture_${number}_posY" value="0" tabindex="-1">
			</div>
			<div class="modal__newwall__furniture__item__unit">mm</div>
		</div>
	</div>
	<button class="modal__repeater__entries__item__del" tabindex="-1"></button>
`;
		
		const numberInputs = dom.querySelectorAll("input[type='number']");
		if(numberInputs) this.rounding(numberInputs);

		return dom;
	}

	blankWall(){
		const wall = {
			id: 0,
			is_finished: false,
			dimension: {
				width: 0,
				height: 0,
				padding_vertical: 50,
				padding_horizontal: 50
			},
			settings: {
				name: "",
				material: "wallpaper",
				mediatype: "plain",
				interiorsheet_method: "normal",
				isFurnitureVisible: true,
				staff: null
			},
			panels: {
				origin: "left",
				height: 0,
				width: []
			},
			corner: [],
			furniture:[],
			wallpaper: {
				catno: "P021A",
				name: "SIGHT",
				imgPath: "/images/P021A-1.png",
				repeat: "horizontal",
				reverseRepeat: false,
				allow_reverseRepeat: false,
				allow_wallpanel: true,
				allow_variable_scale: false,
				allow_repeat_x: true,
				allow_repeat_y: false,
				fillactive: {
					top: false,
					right: false,
					bottom: false,
					left: false
				},
				fillcolor: {
					top: null,
					right: null,
					bottom: null,
					left: null
				},
				dimension: {
					natural_width: 1500,
					natural_height: 900,
					width: 1500,
					height: 900
				},
				tolerance: {
					actual:{
						min: 0,
						max: true
					},
					variable:{
						width:{
							min:0,
							max:true
						},
						height:{
							min:0,
							max:true
						}
					}
				},
				position: {
					x: 0,
					y: 0
				}
			},
			allocation: {
				origin: "right",
				padding: "left",
				tombo: {
					active: true,
					type: "right",
					origin: "right",
					position: 50,
				},
				constructor_msg: {
					autotext: null,
					manualtext: null
				},
				factory_msg: {
					autotext: null,
					manualtext: null
				},
				notice_msg: null,
				rollwidth: 1260
			}
		}
		return wall;
	}

}