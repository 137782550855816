import Editor from './_editor.js';
export default class Review{
	constructor(){
		if(document.body.classList.contains("review")){
			const editorRoot = document.querySelector(".sheet__main__preview.editor")
			this.editor = new Editor(editorRoot)
			this.editor.InitAsPreview();
			this.initialize();
		}
	}

	initialize(){
		if(!document.body.classList.contains("review")) return;

		const sheet = document.querySelector("#sheet");
		const projectNameField = document.querySelector(".sheet__header__projectname__name__label");
		const productNameField = document.querySelector(".sheet__header__product__name");
		const productCatnoField = document.querySelector(".sheet__header__product__catno");
		const mediatypeCheckFields = document.querySelectorAll(".sheet__header__mediatype .sheet__header__checks__item");
		const paddingBlock = document.querySelector(".sheet__header__padding");
		const paddingCheckFields = document.querySelectorAll(".sheet__header__padding .sheet__header__checks__item");
		const overPaddingBlock = document.querySelector(".sheet__header__overpadding");
		const overPaddingVerticalFields = document.querySelector(".sheet__header__overpadding__vertical");
		const overPaddingHorizontalFields = document.querySelector(".sheet__header__overpadding__horizontal");
		const dimensionData = document.querySelector(".sheet__header__dimension__data");
		const wallDimensionField = document.querySelector(".sheet__header__dimension__wall__field");
		const rollDimensionField = document.querySelector(".sheet__header__dimension__roll__field");
		const directionCheckFields = document.querySelectorAll(".sheet__header__direction .sheet__header__checks__item");
		const noticeTextFields = document.querySelector(".sheet__header__notice__text");
		const constructorTextFields = document.querySelector(".sheet__header__constructor .sheet__header__textarea");
		const factoryTextFields = document.querySelector(".sheet__header__factory .sheet__header__textarea");
		const sheetname = document.querySelector(".sheet__header__sheetname");
		const credit_wallname = document.querySelector(".sheet__header__projectname__name__credit > span");

		const wall = window.project.walls[window.control.currentWall];

		// WhO割付図_レンゴー東京工場内新SF／エントランス
		document.title = "WhO割付図_"+window.project.title+"／"+wall.settings.name;
		sheet.setAttribute("data-material", wall.settings.material);

		projectNameField.innerText = window.project.title;
		productNameField.innerText = wall.wallpaper.name;
		productCatnoField.innerText = wall.wallpaper.catno;
		Array.from(mediatypeCheckFields).find(item=>{
			let mediatype = wall.settings.material=="wallpaper" ? wall.settings.mediatype : wall.settings.material;
			return item.dataset.name == mediatype;
		})?.classList.add("checked");
		if(wall.settings.material=="interior_sheet" && wall.settings.interiorsheet_method=="panel"){
			overPaddingHorizontalFields.innerText = "+"+wall.dimension.padding_horizontal+"mm";
		}else{
			Array.from(paddingCheckFields).find(item=>{
				return item.dataset.name == wall.allocation.padding;
			})?.classList.add("checked");
		}
		wallDimensionField.innerText = "W"+wall.dimension.width+"mm × H"+wall.dimension.height+"mm";
		rollDimensionField.innerHTML = this.editor.param_alloc_value_text;
		if(wall.settings.material=="interior_sheet" && wall.settings.interiorsheet_method=="panel"){
			const roll_total = dimensionData.querySelector(".sheet__header__dimension__roll").cloneNode(true);
			const roll_total_dt = roll_total.querySelector("dt");
			const roll_total_dd = roll_total.querySelector("dd");
			roll_total.classList.add("total");
			roll_total_dt.innerText = "合計";
			roll_total_dd.innerHTML = this.editor.alloc_rolls.length+"巾（W"+this.editor.alloc_width+"mm） × H"+this.editor.alloc_height+"mm";
			dimensionData.append(roll_total);
		}
		Array.from(directionCheckFields).find(item=>{
			return item.dataset.name == wall.allocation.origin;
		})?.classList.add("checked");
		noticeTextFields.innerText = wall.allocation.notice_msg;
		constructorTextFields.innerText = wall.allocation.constructor_msg.autotext;
		if(wall.allocation.constructor_msg.manualtext){
			constructorTextFields.innerText += "\r\n"+wall.allocation.constructor_msg.manualtext;		
		}
		
		let factoryText = "<em>【支給データ】</em> ";
		if(wall.allocation.factory_msg.data == "available"){
			factoryText += "有り";
		}else if(wall.allocation.factory_msg.data == "repeatdata"){
			factoryText += "無し（左右リピートデータ）";
		}else{
			factoryText += "無し（通常品番）";
		}
		factoryText += "　<em>【サンプル制作】</em> ";
		if(wall.allocation.factory_msg.sample == "available"){
			factoryText += "有り[No."+wall.allocation.factory_msg.sampleno+"]";
		}else{
			factoryText += "無し（発注メールにて格納先参照）";
		}
		factoryText += "\r\n<em>【色指示】</em> ";
		if(wall.allocation.factory_msg.color == "available"){
			const colornumber = wall.allocation.factory_msg.colornumber ? wall.allocation.factory_msg.colornumber : "-";
			const colordetail = wall.allocation.factory_msg.colordetail ? wall.allocation.factory_msg.colordetail : "-";
			const colordisplay = wall.allocation.factory_msg.colordisplay ? "する" : "しない";
			factoryText += "有り [色番号："+colornumber+" ／ 現物サンプル："+colordetail+" ／ 画面合わせ："+colordisplay;
			if(wall.allocation.factory_msg.colorother){
				factoryText += " ／ その他："+wall.allocation.factory_msg.colorother;
			}
			factoryText += "]";
		}else{
			factoryText += "無し（データなり）";
		}
		factoryText += "　<em>【トンボ】</em> 施工店向け指示に記載\r\n-\r\n";
		factoryText += wall.allocation.factory_msg.autotext;
		if(wall.allocation.factory_msg.manualtext){
			factoryText += "\r\n"+wall.allocation.factory_msg.manualtext;
		}
		factoryTextFields.innerHTML = factoryText;

		const update_date = new Date(wall.last_update);
		let namestring;
		namestring = update_date.getFullYear()+"/"+String(update_date.getMonth()+1).padStart(2, '0')+"/"+String(update_date.getDate()).padStart(2, '0');
		namestring += " "+String(update_date.getHours()).padStart(2, '0')+":"+String(update_date.getMinutes()).padStart(2, '0');
		if(wall.settings.staff){
			namestring += " - "+wall.settings.staff;
		}
		sheetname.innerText = namestring;

		credit_wallname.innerText = wall.settings.name;


		if(Array.isArray(wall.errors)){
			if(wall.errors.length > 0){
				const errors = document.querySelector(".review__errors");
				const errorLabels = errors.querySelectorAll(".review__errors__error");
				errors.classList.add("show");
				errorLabels.forEach(label=>{
					label.classList.remove("show");
					if(wall.errors.some(error=>error==label.id)){
						label.classList.add("show");
					}
				});
			}
		}


		const completeBtn = document.querySelector(".review__complete__label");
		const toggleComplete = ()=>{
			if(wall.is_finished){
				wall.is_finished = false;
			}else{
				wall.is_finished = true;
			}
			save();
		}
		completeBtn.addEventListener("click", toggleComplete, false);
		if(wall.is_finished) completeBtn.classList.add("checked");

		const project_id = document.body.getAttribute("data-pid");
		const project_slug = document.body.getAttribute("data-slug");
		const save = ()=>{
			const savepromise = window.DataControll.save(project_id, true);
			completeBtn.classList.remove("save_failed");
			completeBtn.classList.remove("save_done");
			completeBtn.classList.add("saving");
			savepromise.then(result => {
				completeBtn.classList.remove("saving");
				if(wall.is_finished){
					completeBtn.classList.add("checked");
				}else{
					completeBtn.classList.remove("checked");
				}
			});
			savepromise.catch(result => {
				completeBtn.classList.remove("saving");
				if(wall.is_finished){
					completeBtn.classList.add("checked");
				}else{
					completeBtn.classList.remove("checked");
				}
				alert("更新に失敗しました\r\nresult");
			});
		}


		const allocSheetBtn = document.querySelector(".alloc_sheet");
		const packingSheetBtn = document.querySelector(".packing_sheet");
		/*
		const downloadSheetImage = (savename)=>{
			htmlToImage.toPng(sheet).then(function (dataUrl) {
				var link = document.createElement('a');
				link.download = savename;
				link.href = dataUrl;
				link.click();
			})
			.catch(function (error) {
				console.error('error', error);
			});
			html2canvas(sheet).then(function(canvas) {
				document.body.appendChild(canvas);
			});
		};
		allocSheetBtn.addEventListener("click", ()=>{downloadSheetImage("allocation_sheet.png")}, false);
		packingSheetBtn.addEventListener("click", ()=>{downloadSheetImage("packing_sheet.png")}, false);
		*/
		allocSheetBtn.addEventListener("click", (e)=>{
			sheet.classList.remove("packing_sheet");
			requestAnimationFrame(()=>{requestAnimationFrame(()=>{
				window.print();
			})});
		}, false);
		packingSheetBtn.addEventListener("click", ()=>{
			sheet.classList.add("packing_sheet");
			requestAnimationFrame(()=>{requestAnimationFrame(()=>{
				window.print();
			})});
		}, false);
	}
}