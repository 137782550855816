export default class WallpaperData{
	constructor(){
		const wallpaperDataPath = "https://whohw.jp/whohw_wp/appdesigndata.json";
		this.isLoaded = false;
		this.loadPromise = new Promise((resolve, reject) => {
			const XHR = new XMLHttpRequest();
			XHR.addEventListener('load', (res) => {
				if(res.target.readyState == 4 && res.target.status == 200){
					this.data = res.target.response;
					this.isLoaded = false;
					resolve();
				}else{
					reject('response error');
				}
			});
			XHR.addEventListener('error', (res) => {
				reject('request error');
			});
			XHR.open('GET', wallpaperDataPath, true);
			XHR.responseType = 'json';
			XHR.send();
		});
	}
	isLoaded(){
		return this.isLoaded;
	}
	getPromise(){
		return this.loadPromise;
	}
	get(catno){
		let data = false;
		if(Array.isArray(this.data)){
			const found = this.data.find(elm=>elm.catno.toLowerCase() == catno.toLowerCase());
			if(found) data = found;
		}
		return data;
	}
	getSeries(catno){
		let data = false;
		if(Array.isArray(this.data)){
			const current = this.get(catno);
			if(current){
				const found = this.data.filter(elm=>elm.slug.toLowerCase() == current.slug.toLowerCase());
				if(found) data = found;
			}
		}
		return data;
	}
	getRandom(){
		let data = false;
		if(Array.isArray(this.data)){
			data = this.data[Math.floor(Math.random()*this.data.length)];
		}
		return data;
	}
}