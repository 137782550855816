import 'nodelist-foreach-polyfill';
import Cookies from 'js-cookie';
import DataControll from './modules/_dataControll.js';
import Login from './modules/_login.js';
import Edit from './modules/_edit.js';
import Review from './modules/_review.js';
import ListIndex from './modules/_listindex.js';
import WallpaperData from './modules/_wallpaperData.js';

class App {
	constructor() {
		window.DataControll = new DataControll();
		window.DataControll.restoreFromLocal();
		window.WallpaperData = new WallpaperData();
		this.Login = new Login();
		this.Edit = new Edit();
		this.Review = new Review();
		this.ListIndex = new ListIndex();
	}
}

document.addEventListener('DOMContentLoaded', () => {
	window.isTouch = false;
	if( document.ontouchstart !== undefined ) {
		window.isTouch = true;
		document.body.classList.add('touch');
	}

	// let isAlreadyLogin = Cookies.get("whowh_temporary-build-login") ? true : false;
	// if(!isAlreadyLogin && location.href.indexOf("login.html")==-1){
	// 	location.href = "/login.html";
	// }

	window.project = {
		id: 1,
		title: "レンゴー東京工場内新SR",
		create_date: "2024-10-10",
		update_date: "2024-10-11 15:27:00",
		walls: [
			{
				id: 1,
				is_finished: false,
				dimension: {
					width: 6000,
					height: 2700,
					padding_vertical: 50,
					padding_horizontal: 50
				},
				settings: {
					name: "会議室 A",
					material: "interior_sheet",
					mediatype: "stucco",
					interiorsheet_method: "panel",
					isFurnitureVisible: true,
					staff: null
				},
				panels: {
					origin: "left",
					height: 0,
					width: [
						900,
						1050,
						1050,
						1050,
						1050,
						900,
					]
				},
				corner: [
					{
						type: "inner",
						origin: "left",
						position: 900,
					},
					{
						type: "outer",
						origin: "right",
						position: 900,
					},
				],
				furniture:[
					{
						label: "カウンター",
						width: 2200,
						height: 1000,
						origin_x: "left",
						position_x: 900,
						origin_y: "bottom",
						position_y: 0,
					},
				],
				wallpaper: {
					catno: "P021A",
					name: "SIGHT",
					imgPath: "/images/P021A-1.png",
					repeat: "horizontal",
					reverseRepeat: false,
					allow_reverseRepeat: false,
					allow_wallpanel: true,
					allow_variable_scale: false,
					allow_repeat_x: true,
					allow_repeat_y: false,
					fillactive: {
						top: true,
						right: false,
						bottom: true,
						left: false
					},
					fillcolor: {
						top: "#ccd6dc",
						right: null,
						bottom: "#fef100",
						left: null
					},
					// fillcolor: {
					// 	top: null,
					// 	right: "#9bb3ba",
					// 	bottom: null,
					// 	left: "#697a85"
					// },
					dimension: {
						natural_width: 1500,
						natural_height: 900,
						width: 4667,
						height: 2800
					},
					tolerance: {
						actual:{
							min:0,
							max:true
						},
						variable:{
							width:{
								min:0,
								max:true
							},
							height:{
								min:0,
								max:true
							}
						}
					},
					position: {
						x: 0,
						y: 0
					}
				},
				allocation: {
					origin: "right",
					padding: "left",
					tombo: {
						active: true,
						type: "right",
						origin: "right",
						position: 50,
					},
					constructor_msg: {
						autotext: null,
						manualtext: null
					},
					factory_msg: {
						autotext: null,
						manualtext: null,
						data: "catno",
						sample: "none",
						sampleno: null,
						color: "none",
						colornumber: null,
						colordetail: null,
						colordisplay: false,
						colorother: null,
					},
					notice_msg: null,
					rollwidth: 1260
				},
				errors: []
			},
		]
	}
	window.control = {
		editor: {
			zoom: 9,	//デフォルト100%
			moveOffsetX: 0,
			moveOffsetY: 0,
		},
		currentProject: window.project,
		currentWall: 0,
		isSettingPanelOpen: false
	};
	window.settings = {
		grandscale: 0.1,
		defaultRollWidth: 900,
		panelMaxWidth: 1260
	};


	const rounding = ()=>{
		const numberInputs = document.querySelectorAll("input[type='number']");
		numberInputs.forEach((ni)=>{
			ni.addEventListener("change", ()=>{
				ni.value = Math.round(ni.value);
			});
		});
	}
	rounding();

	let app = new App();
}, false);