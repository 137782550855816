import Cookies from 'js-cookie';
export default class Login{
	constructor(){
		if(document.body.classList.contains("login")){
			this.initLoginPage();
		}
	}
	initLoginPage(){
		const setBgWallpaper = ()=>{
			const randomWallPaper = window.WallpaperData.getRandom();
			document.body.style.backgroundImage = "url("+randomWallPaper.imgPath+")";
		}
		if(window.WallpaperData.isLoaded === true){
			setBgWallpaper();
		}else{
			const promise = window.WallpaperData.getPromise();
			promise.then(setBgWallpaper);
			promise.catch(err => {
				document.body.style.backgroundImage = "url(/images/P021A-1.png)";
			});
		}

		/*
		const button = document.querySelector(".login__window__send__button");
		button.addEventListener("click", ()=>{
			Cookies.set("whowh_temporary-build-login", true);
		}, false);
		*/
	}
}