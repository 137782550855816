import Factory from './_factory.js';
import Modal from './_modal.js';
import Editor from './_editor.js';
export default class ListIndex{
	constructor(){
		if(document.body.classList.contains("list--index")){
			this.Factory = new Factory();
			this.Modal = new Modal();
			this.initializeListIndex();
		}
		if(document.body.classList.contains("list--project")){
			this.Factory = new Factory();
			this.Modal = new Modal();
			this.initializeListProject();
		}
	}

	initializeListIndex(){
		const newProjectBtn = document.querySelector(".list__index__add__btn");
		const createProjectBtn = document.querySelector(".modal__newproject__field__button");
		const projectNameInput = document.querySelector(".modal__newproject__field__input");
		newProjectBtn.addEventListener("click", ()=>{
			this.Modal.openModal("#modal__newproject");
		}, false);
		createProjectBtn.addEventListener("click", ()=>{
			this.Modal.startBusy();
			const titleParam =  new URLSearchParams({title: projectNameInput.value}).toString();
			const XHR = new XMLHttpRequest();
			XHR.addEventListener('load', (res) => {
				if(res.target.readyState == 4 && res.target.status == 200){
					if(res.target.response?.status == "success"){
						window.location.href = res.target.response.data;
						this.Modal.endBusy();
						this.Modal.closeModal();
					}else{
						this.Modal.endBusy();
						this.Modal.closeModal();
						alert("新規作成に失敗しました\r\npost insert failed\r\n"+res.target.response?.message);
					}
				}else{
					this.Modal.endBusy();
					this.Modal.closeModal();
					alert("新規作成に失敗しました\r\nresponse error\r\n"+res.target.status);
				}
			});
			XHR.addEventListener('error', (res) => {
				this.Modal.endBusy();
				this.Modal.closeModal();
				alert("新規作成に失敗しました\r\nrequest error\r\n"+res);
			});
			XHR.open('GET', "/api/insert_post.php?"+titleParam, true);
			XHR.responseType = 'json';
			XHR.send();
		}, false);

		const copySuccessMsgs = document.querySelectorAll(".list__index__cell--urlcopy__msg.success");
		const copyErrorMsgs = document.querySelectorAll(".list__index__cell--urlcopy__msg.error");
		let copyMsgTimer = null;
		const showCopyMsg = (target)=>{
			copySuccessMsgs.forEach(item=>item.classList.remove("show"));
			copyErrorMsgs.forEach(item=>item.classList.remove("show"));
			if(copyMsgTimer) clearTimeout(copyMsgTimer);
			requestAnimationFrame(()=>{requestAnimationFrame(()=>{
				target.classList.add("show");
				copyMsgTimer = setTimeout(()=>{
					target.classList.remove("show");	
				}, 1500);
			})});
		}

		const deleteModal = document.querySelector("#modal__deleteproject");
		const deleteModalTitle = deleteModal.querySelector(".modal__deleteproject__title");
		let deleteTargetID = null;
		const deleteModalInit = ()=>{
			const cancelBtn = deleteModal.querySelector(".modal__dialogue__control__negative");
			const execBtn = deleteModal.querySelector(".modal__dialogue__control__positive");
			cancelBtn.addEventListener("click", ()=>{
				this.Modal.closeModal();
			}, false);
			execBtn.addEventListener("click", ()=>{
				this.Modal.startBusy();

				const targetParam =  new URLSearchParams({target: deleteTargetID}).toString();
				const XHR = new XMLHttpRequest();
				XHR.addEventListener('load', (res) => {
					if(res.target.readyState == 4 && res.target.status == 200){
						// const deletedItem = document.querySelector(".list__index__entry[data-pid='"+deleteTargetID+"']");
						// if(deletedItem) deletedItem.remove();
						// this.Modal.endBusy();
						// this.Modal.closeModal();
						window.location.reload();
					}else{
						this.Modal.endBusy();
						this.Modal.closeModal();
						alert("削除に失敗しました\r\nresponse error\r\n"+res.target.status);
					}
				});
				XHR.addEventListener('error', (res) => {
					this.Modal.endBusy();
					this.Modal.closeModal();
					alert("削除に失敗しました\r\nrequest error\r\n"+res);
				});
				XHR.open('GET', "/api/delete_post.php?"+targetParam, true);
				XHR.send();
			}, false);
		}
		const popupDeleteModal = (targetID, title)=>{
			deleteModalTitle.innerHTML = title;
			deleteTargetID = targetID;
			this.Modal.openModal("#modal__deleteproject");
		}
		deleteModalInit();

		const projectitems = document.querySelectorAll(".list__index__entry");
		projectitems.forEach(item=>{
			const pid = item.dataset.pid;
			const slug = item.dataset.slug;
			const link = item.querySelector(".list__index__entrylink");
			const title = item.querySelector(".list__index__cell--projectname").innerHTML;
			const url = link.getAttribute("href");

			const copyBtn = item.querySelector(".list__urlcopy-btn");
			const successMsg = item.querySelector(".list__index__cell--urlcopy__msg.success");
			const errorMsg = item.querySelector(".list__index__cell--urlcopy__msg.error");
			copyBtn.addEventListener("click", ()=>{
				navigator.clipboard.writeText(url).then(
					() => { showCopyMsg(successMsg); },
					() => { showCopyMsg(errorMsg); },
				);
			});

			const deleteBtn = item.querySelector(".list__discard-btn");
			deleteBtn.addEventListener("click", ()=>{
				popupDeleteModal(pid, title);
			}, false);
		});
	}

	initializeListProject(){
		const newProjectBtn = document.querySelector(".list__index__add__btn");

		const previewDom1 = document.querySelector(".modal__newwall__settings .modal__livesetting__preview.editor");
		const previewDom2 = document.querySelector(".modal__newwall__furniture .modal__livesetting__preview.editor");
		let previewEditor1 = null;
		let previewEditor2 = null;
		let newWallData = null;

		const updatePreviewEditors = ()=>{
			previewEditor1.InitAsNewWall();
			previewEditor2.InitAsNewWall();
		}

		const deleteModal = document.querySelector("#modal__deleteproject");
		const deleteModalTitle = deleteModal.querySelector(".modal__deletewall__title");
		let deleteTargetID = null;
		const deleteModalInit = ()=>{
			const cancelBtn = deleteModal.querySelector(".modal__dialogue__control__negative");
			const execBtn = deleteModal.querySelector(".modal__dialogue__control__positive");
			cancelBtn.addEventListener("click", ()=>{
				this.Modal.closeModal();
			}, false);
			execBtn.addEventListener("click", ()=>{
				this.Modal.startBusy();
				const pid = document.body.getAttribute("data-pid");
				const wid = deleteTargetID;
				const targetParam =  new URLSearchParams({
					pid: pid,
					wid: deleteTargetID
				}).toString();
				const XHR = new XMLHttpRequest();
				XHR.addEventListener('load', (res) => {
					if(res.target.readyState == 4 && res.target.status == 200){
						if(res.target.response?.status !== "ok"){
							this.Modal.endBusy();
							this.Modal.closeModal();
							alert("削除に失敗しました\r\nprocess error\r\n"+res.target.response?.message);
						}
						window.location.reload();
					}else{
						this.Modal.endBusy();
						this.Modal.closeModal();
						alert("削除に失敗しました\r\nresponse error\r\n"+res.target.status);
					}
				});
				XHR.addEventListener('error', (res) => {
					this.Modal.endBusy();
					this.Modal.closeModal();
					alert("削除に失敗しました\r\nrequest error\r\n"+res);
				});
				XHR.open('GET', "/api/delete_wall.php?"+targetParam, true);
				XHR.responseType = 'json';
				XHR.send();
			}, false);
		}
		const popupDeleteModal = (targetID, title)=>{
			deleteModalTitle.innerHTML = title;
			deleteTargetID = targetID;
			this.Modal.openModal("#modal__deleteproject");
		}
		const deleteProjectBtn = document.querySelectorAll(".list__discard-btn");
		deleteProjectBtn.forEach(btn=>{
			btn.addEventListener("click", (e)=>{
				const entry = btn.closest(".list__index__entry");
				const wid = entry.getAttribute("data-wid");
				const title = entry.querySelector(".list__index__cell--wallname").innerHTML;
				popupDeleteModal(wid, title);
			}, false);
		});
		deleteModalInit();


		const modal = document.querySelector("#modal__newwall");
		const modalPrevBtn = document.querySelector(".modal__page__nav__prev");
		const modalNextBtn = document.querySelector(".modal__page__nav__next");
		const modalDoneBtn = document.querySelector(".modal__page__nav__done");
		let modalProgress = 1;
		modalPrevBtn.addEventListener("click", ()=>{
			modalProgress = Math.max(1, modalProgress-1);
			modal.setAttribute("data-progress", modalProgress);
			if(!getValidation()){
				modalNextBtn.classList.add("disable");
				modalDoneBtn.classList.add("disable");
			}else{
				modalNextBtn.classList.remove("disable");
				modalDoneBtn.classList.remove("disable");
			}
		}, false);
		modalNextBtn.addEventListener("click", ()=>{
			if(getValidation()){
				modalProgress = Math.min(5, modalProgress+1);
				modal.setAttribute("data-progress", modalProgress);
				if(!getValidation()){
					modalNextBtn.classList.add("disable");	
					modalDoneBtn.classList.add("disable");
				}else{
					modalNextBtn.classList.remove("disable");	
					modalDoneBtn.classList.remove("disable");
				}
			}else{
				if(modalProgress == 1){
					wallnameErrorMsg.classList.add("show");
				}else if(modalProgress == 2){
					catnoErrorMsg.classList.add("show");
				}else if(modalProgress == 3){
					dimensionErrorMsg.classList.add("show");
				}else if(modalProgress == 4 && isPanelMode){
					panelErrorMsg.classList.add("show");
				}
			}
		}, false);
		modalDoneBtn.addEventListener("click", (e)=>{
			if(!getValidation()){
				if(modalProgress == 1){
					wallnameErrorMsg.classList.add("show");
				}else if(modalProgress == 2){
					catnoErrorMsg.classList.add("show");
				}else if(modalProgress == 3){
					dimensionErrorMsg.classList.add("show");
				}else if(modalProgress == 4 && isPanelMode){
					panelErrorMsg.classList.add("show");
				}
				e.preventDefault();
				e.stopPropagation();
				return false;
			}
		}, false);

		const attachPulldownChangeEvent = (pulldown)=>{
			const label = pulldown.querySelector("label");
			const select = pulldown.querySelector("select");
			select.addEventListener("change", ()=>{
				label.innerText = pulldown.querySelector("option[value='"+select.value+"']").innerText;
			}, false);
		}
		const selectors = document.querySelectorAll(".modal__selector");
		selectors.forEach(elm=>{attachPulldownChangeEvent(elm)});

		const wallnameErrorMsg = modal.querySelector(".modal__newwall__wallname .modal__page__body__errors");
		const wallnameErrorMsg_name = modal.querySelector(".modal__newwall__wallname .modal__page__body__errors #error_name");
		const wallnameErrorMsg_staff = modal.querySelector(".modal__newwall__wallname .modal__page__body__errors #error_staff");
		const catnoErrorMsg = modal.querySelector(".modal__newwall__product .modal__page__body__errors");
		const dimensionErrorMsg = modal.querySelector(".modal__newwall__dimension .modal__page__body__errors");
		const panelErrorMsg = modal.querySelector(".modal__newwall__settings .modal__page__body__errors");
		let isWallNameValid = false;
		let isStaffNameValid = false;
		let isCatnoValid = false;
		let isDimensionValid = false;
		let isPanelValid = false;
		let isWallpanel = false;

		const update_wallname = ()=>{
			newWallData.settings.name = nameInputField.value;
			newWallData.settings.staff = staffInputField.value;
			isWallNameValid = !!(nameInputField.value);
			isStaffNameValid = !!(staffInputField.value);
			if(modalProgress==1){
				wallnameErrorMsg_name.style.display = isWallNameValid ? "none" : "flex";
				wallnameErrorMsg_staff.style.display = isStaffNameValid ? "none" : "flex";
				if(isWallNameValid && isStaffNameValid){
					wallnameErrorMsg.classList.remove("show");
					modalNextBtn.classList.remove("disable");
				}else{
					modalNextBtn.classList.add("disable");
				}
			}
		}
		const update_catno = ()=>{
			console.log("型番更新処理");
			const thumb = document.querySelector(".modal__newwall__product__design__img");
			const thumbimg = thumb.querySelector("img");
			if(thumbimg) thumbimg.remove();
			const wpdata = window.WallpaperData.get(catnoInputField.value);
			if(wpdata){
				newWallData.wallpaper.catno = wpdata.catno;
				newWallData.wallpaper.name = wpdata.name;
				newWallData.wallpaper.imgPath = wpdata.imgPath;
				// newWallData.wallpaper.allow_variable_scale = wpdata.allow_variable_scale;
				newWallData.wallpaper.allow_wallpanel = wpdata.allow_wallpanel;
				newWallData.wallpaper.repeat = wpdata.repeat;
				newWallData.wallpaper.allow_repeat_x = wpdata.allow_repeat_x;
				newWallData.wallpaper.allow_repeat_y = wpdata.allow_repeat_y;
				newWallData.wallpaper.reverseRepeat = false;
				newWallData.wallpaper.allow_reverseRepeat = wpdata.allow_reverseRepeat;
				if(wpdata.fillcolor.top){
					newWallData.wallpaper.fillcolor.top = wpdata.fillcolor.top;
					newWallData.wallpaper.fillactive.top = true;
				}
				if(wpdata.fillcolor.right){
					newWallData.wallpaper.fillcolor.right = wpdata.fillcolor.right;
					newWallData.wallpaper.fillactive.right = true;
				}
				if(wpdata.fillcolor.bottom){
					newWallData.wallpaper.fillcolor.bottom = wpdata.fillcolor.bottom;
					newWallData.wallpaper.fillactive.bottom = true;
				}
				if(wpdata.fillcolor.left){
					newWallData.wallpaper.fillcolor.left = wpdata.fillcolor.left;
					newWallData.wallpaper.fillactive.left = true;
				}
				newWallData.wallpaper.dimension.natural_width = wpdata.natural_width;
				newWallData.wallpaper.dimension.natural_height = wpdata.natural_height;
				newWallData.wallpaper.tolerance = wpdata.tolerance;
				newWallData.wallpaper.tolerance.actual.max = (newWallData.wallpaper.tolerance.actual.max===true) ? Number.MAX_SAFE_INTEGER : newWallData.wallpaper.tolerance.actual.max;
				newWallData.wallpaper.tolerance.variable.width.max = (newWallData.wallpaper.tolerance.variable.width.max===true) ? Number.MAX_SAFE_INTEGER : newWallData.wallpaper.tolerance.variable.width.max;
				newWallData.wallpaper.tolerance.variable.height.max = (newWallData.wallpaper.tolerance.variable.height.max===true) ? Number.MAX_SAFE_INTEGER : newWallData.wallpaper.tolerance.variable.height.max;
				if(newWallData.dimension.width && newWallData.dimension.height){
					newWallData.wallpaper.dimension.height = Math.min(newWallData.wallpaper.tolerance.actual.max, newWallData.dimension.height+newWallData.dimension.padding_vertical*2);
					newWallData.wallpaper.dimension.width = newWallData.wallpaper.dimension.natural_width/newWallData.wallpaper.dimension.natural_height * newWallData.dimension.height
				}
				newWallData.settings.mediatype = wpdata.mediatype;

				const material_selection_wallpanel = document.querySelector("select[name='newwall_material'] option[value='wallpanel']");
				if(wpdata.allow_wallpanel === false){
					if(materialInputField.value == "wallpanel"){
						materialInputField.value = "wallpaper";
						materialInputField.dispatchEvent(new Event('change'));
					}
					material_selection_wallpanel.setAttribute("disabled", "disabled");
				}else{
					material_selection_wallpanel.removeAttribute("disabled");
				}

				const thumbimg = document.createElement("img");
				thumbimg.setAttribute("src", wpdata.imgPath);
				thumb.append(thumbimg);
				isCatnoValid = true;
			}else{
				isCatnoValid = false;
			}
			if(modalProgress==2){
				if(isCatnoValid){
					catnoErrorMsg.classList.remove("show");
					modalNextBtn.classList.remove("disable");
				}else{
					modalNextBtn.classList.add("disable");
				}
			}
		}
		const update_material = ()=>{
			console.log("マテリアル更新処理");
			if(materialInputField.value == "interior_sheet"){
				productPage.classList.add("interiorsheet_selected");
			}else{
				productPage.classList.remove("interiorsheet_selected");
			}
			if(materialInputField.value == "wallpanel"){
				modal.classList.add("wallpanel_selected");
				isWallpanel = true;
			}else{
				modal.classList.remove("wallpanel_selected");
				isWallpanel = false;
			}
			isPanelMode = (materialInputField.value == "interior_sheet" && methodInputField.value == "panel");
			if(isPanelMode){
				modal.classList.add("panelMode");
			}else{
				modal.classList.remove("panelMode");
				newWallData.settings.interiorsheet_method = "normal";
			}
			newWallData.settings.material = materialInputField.value;
			update_panel();
			updatePreviewEditors();
		}
		const update_method = ()=>{
			console.log("INTERIOR SHEET 工法更新処理");
			isPanelMode = (materialInputField.value == "interior_sheet" && methodInputField.value == "panel");
			if(isPanelMode){
				modal.classList.add("panelMode");
			}else{
				modal.classList.remove("panelMode");
			}
			newWallData.settings.interiorsheet_method = methodInputField.value;
			update_panel();
			updatePreviewEditors();
		}
		const update_dimension = ()=>{
			console.log("寸法更新処理");
			newWallData.dimension.width = parseInt(dimensionWidthInputField.value, 10);
			newWallData.dimension.height = parseInt(dimensionHeightInputField.value, 10);
			newWallData.wallpaper.dimension.height = Math.min(newWallData.wallpaper.tolerance.actual.max, newWallData.dimension.height+newWallData.dimension.padding_vertical*2);
			newWallData.wallpaper.dimension.width = newWallData.wallpaper.dimension.natural_width/newWallData.wallpaper.dimension.natural_height * newWallData.dimension.height
			updatePreviewEditors();
			isDimensionValid = (newWallData.dimension.width*newWallData.dimension.height)>0;
			if(modalProgress==3){
				if(isDimensionValid){
					dimensionErrorMsg.classList.remove("show");
					modalNextBtn.classList.remove("disable");
					modalDoneBtn.classList.remove("disable");
				}else{
					modalNextBtn.classList.add("disable");
					modalDoneBtn.classList.add("disable");
				}
			}
		}
		const update_corner = ()=>{
			console.log("入隅／出隅更新処理");
			const new_corners = [];
			const corner_items = document.querySelectorAll(".modal__newwall__corner .modal__repeater__entries__item");
			corner_items.forEach(item=>{
				const item_type = item.querySelector(".modal__newwall__corner__item__type select");
				const item_origin = item.querySelector(".modal__newwall__corner__item__origin select");
				const item_value = item.querySelector(".modal__newwall__corner__item__size input");
				const corner = {};
				corner.type = item_type.value;
				corner.origin = item_origin.value;
				corner.position = item_value.value;
				new_corners.push(corner);
			});
			newWallData.corner = new_corners;
			updatePreviewEditors();
		}
		const update_panel = ()=>{
			console.log("パネル更新処理");
			const new_panels = [];
			const panel_items = document.querySelectorAll(".modal__newwall__panels .modal__repeater__entries__item");
			const panel_total = document.querySelector(".modal__newwall__panels__total__amount");
			let total_width = 0;
			panel_items.forEach(item=>{
				const item_origin = item.querySelector(".modal__newwall__panels__item__origin select");
				const item_height = item.querySelector(".modal__newwall__panels__item__height input");
				const item_width = parseInt(item.querySelector(".modal__newwall__panels__item__width input").value, 10);
				new_panels.push(item_width);
				total_width += item_width;
			});
			panel_total.innerText = total_width;
			newWallData.panels.origin = panel_items.item(0)?.querySelector(".modal__newwall__panels__item__origin select").value;
			newWallData.panels.height = panel_items.item(0)?.querySelector(".modal__newwall__panels__item__height input").value;
			newWallData.panels.width = new_panels;
			isPanelValid = (total_width==newWallData.dimension.width) || !isPanelMode;
			if(modalProgress==4){
				if(isPanelValid){
					panelErrorMsg.classList.remove("show");
					modalNextBtn.classList.remove("disable");
				}else{
					modalNextBtn.classList.add("disable");
				}
			}
			updatePreviewEditors();
		}
		const update_furniture = ()=>{
			console.log("建具・什器更新処理");
			const new_furnitures = [];
			const furniture_items = document.querySelectorAll(".modal__newwall__furniture .modal__repeater__entries__item");
			furniture_items.forEach(item=>{
				const item_label = item.querySelector(".modal__newwall__furniture__item__name input");
				const item_width = item.querySelector(".modal__newwall__furniture__item__width input");
				const item_height = item.querySelector(".modal__newwall__furniture__item__height input");
				const item_origin_x = item.querySelector(".modal__newwall__furniture__item__horizontal select");
				const item_position_x = item.querySelector(".modal__newwall__furniture__item__horizontal input");
				const item_origin_y = item.querySelector(".modal__newwall__furniture__item__vertical select");
				const item_position_y = item.querySelector(".modal__newwall__furniture__item__vertical input");
				const furniture = {};
				furniture.label = item_label.value;
				furniture.width = item_width.value;
				furniture.height = item_height.value;
				furniture.origin_x = item_origin_x.value;
				furniture.position_x = item_position_x.value;
				furniture.origin_y = item_origin_y.value;
				furniture.position_y = item_position_y.value;
				new_furnitures.push(furniture);
			});
			newWallData.furniture = new_furnitures;
			updatePreviewEditors();
		}

		const productPage = document.querySelector(".modal__newwall__product");
		const nameInputField = document.querySelector("input[name='newwall_name']");
		const staffInputField = document.querySelector("input[name='newwall_staff']");
		const catnoInputField = document.querySelector("input[name='newwall_catno']");
		const materialInputField = document.querySelector("select[name='newwall_material']");
		const methodInputField = document.querySelector("select[name='newwall_method']");
		const dimensionWidthInputField = document.querySelector("input[name='newwall_dimension_width']");
		const dimensionHeightInputField = document.querySelector("input[name='newwall_dimension_height']");
		let isPanelMode = false;
		nameInputField.addEventListener("change", update_wallname, false);
		staffInputField.addEventListener("change", update_wallname, false);
		catnoInputField.addEventListener("change", update_catno, false);
		materialInputField.addEventListener("change", update_material, false);
		methodInputField.addEventListener("change", update_method, false);
		dimensionWidthInputField.addEventListener("change", update_dimension, false);
		dimensionHeightInputField.addEventListener("change", update_dimension, false);

		const newWallSettingsEditor = document.querySelector(".modal__newwall__settings .modal__livesetting__editor");
		const newWallAddCornerBtn = document.querySelector(".modal__newwall__corner .modal__repeater__add");
		const newWallCornerEntries = document.querySelector(".modal__newwall__corner .modal__repeater__entries");
		let corner_item_id = 0;
		const add_corner = (type="inner",origin="left",value=0)=>{
			const corner_item_dom = this.Factory.newWallCornerItem(corner_item_id);
			const pulldown = corner_item_dom.querySelectorAll(".modal__selector");
			const type_input = corner_item_dom.querySelector(".modal__newwall__corner__item__type select");
			const origin_input = corner_item_dom.querySelector(".modal__newwall__corner__item__origin select");
			const value_input = corner_item_dom.querySelector(".modal__newwall__corner__item__size input");
			const remove = corner_item_dom.querySelector(".modal__repeater__entries__item__del");
			newWallCornerEntries.append(corner_item_dom);
			pulldown.forEach(pd=>attachPulldownChangeEvent(pd));
			type_input.value = type;
			origin_input.value = origin;
			value_input.value = value;
			type_input.dispatchEvent(new Event('change'));
			origin_input.dispatchEvent(new Event('change'));
			type_input.addEventListener("change", update_corner, false);
			origin_input.addEventListener("change", update_corner, false);
			value_input.addEventListener("change", update_corner, false);
			remove.addEventListener("click", ()=>{
				corner_item_dom.remove();
				update_corner();
			}, false);
			corner_item_id++;
			newWallSettingsEditor.scrollTo(0, Number.MAX_SAFE_INTEGER);
		}
		newWallAddCornerBtn.addEventListener("click", ()=>{ add_corner() }, false);

		const newWallAddPanelBtn = document.querySelector(".modal__newwall__panels .modal__repeater__add");
		const newWallPanelEntries = document.querySelector(".modal__newwall__panels .modal__repeater__entries");
		let panel_item_id = 0;
		const add_panel = (origin="left",height=2700,width=0,positionID=null)=>{
			const panel_item_dom = this.Factory.newWallPanelItem(panel_item_id);
			const pulldown = panel_item_dom.querySelectorAll(".modal__selector");
			const origin_input = panel_item_dom.querySelector(".modal__newwall__panels__item__origin select");
			const width_input = panel_item_dom.querySelector(".modal__newwall__panels__item__width input");
			const height_input = panel_item_dom.querySelector(".modal__newwall__panels__item__height input");
			const duplicate = panel_item_dom.querySelector(".modal__newwall__panels__item__duplicate__btn");
			const remove = panel_item_dom.querySelector(".modal__repeater__entries__item__del");
			let insertPosition = null
			if(positionID != null){
				insertPosition = newWallPanelEntries.querySelector(".modal__repeater__entries__item[data-number='"+positionID+"']");
			}
			if(insertPosition){
				newWallPanelEntries.insertBefore(panel_item_dom, insertPosition.nextSibling);
			}else{
				newWallPanelEntries.append(panel_item_dom);	
			}
			pulldown.forEach(pd=>attachPulldownChangeEvent(pd));
			origin_input.value = origin;
			width_input.value = width;
			height_input.value = height;
			origin_input.dispatchEvent(new Event('change'));
			origin_input.addEventListener("change", ()=>{
				const all_origin = document.querySelectorAll(".modal__newwall__panels__item__origin");
				all_origin.forEach(ao=>{
					const label = ao.querySelector("label");
					const select = ao.querySelector("select");
					select.value = origin_input.value;
					label.innerText = ao.querySelector("option[value='"+select.value+"']").innerText;
				});
			}, false);
			height_input.addEventListener("change", ()=>{
				const all_height = document.querySelectorAll(".modal__newwall__panels__item__height input");
				all_height.forEach(ah=>{
					ah.value = height_input.value;
				});
			}, false);
			origin_input.addEventListener("change", update_panel, false);
			width_input.addEventListener("change", update_panel, false);
			height_input.addEventListener("change", update_panel, false);
			duplicate.addEventListener("click", ()=>{
				const panelitem = duplicate.closest(".modal__repeater__entries__item");
				add_panel(origin_input.value, height_input.value, width_input.value, panelitem.dataset.number);
				update_panel();
			}, false);
			remove.addEventListener("click", ()=>{
				panel_item_dom.remove();
				update_panel();
			}, false);
			panel_item_id++;
		}
		newWallAddPanelBtn.addEventListener("click", ()=>{ add_panel("left", newWallData.dimension.height, 0) }, false);

		const newWallFurnitureEditor = document.querySelector(".modal__newwall__furniture .modal__livesetting__editor");
		const newWallAddFurnitureBtn = document.querySelector(".modal__newwall__furniture .modal__repeater__add");
		const newWallFurnitureEntries = document.querySelector(".modal__newwall__furniture .modal__repeater__entries");
		let furniture_item_id = 0;
		const add_furniture = (
			label="カウンター",
			width=2000,
			height=1000,
			origin_x="left",
			position_x=900,
			origin_y="bottom",
			position_y=0,
		)=>{
			const furniture_item_dom = this.Factory.newWallFurnitureItem(furniture_item_id);
			const pulldown = furniture_item_dom.querySelectorAll(".modal__selector");
			const label_input = furniture_item_dom.querySelector(".modal__newwall__furniture__item__name input");
			const width_input = furniture_item_dom.querySelector(".modal__newwall__furniture__item__width input");
			const height_input = furniture_item_dom.querySelector(".modal__newwall__furniture__item__height input");
			const origin_x_input = furniture_item_dom.querySelector(".modal__newwall__furniture__item__horizontal select");
			const position_x_input = furniture_item_dom.querySelector(".modal__newwall__furniture__item__horizontal input");
			const origin_y_input = furniture_item_dom.querySelector(".modal__newwall__furniture__item__vertical select");
			const position_y_input = furniture_item_dom.querySelector(".modal__newwall__furniture__item__vertical input");
			const remove = furniture_item_dom.querySelector(".modal__repeater__entries__item__del");
			newWallFurnitureEntries.append(furniture_item_dom);
			pulldown.forEach(pd=>attachPulldownChangeEvent(pd));
			label_input.value = label;
			width_input.value = width;
			height_input.value = height;
			origin_x_input.value = origin_x;
			position_x_input.value = position_x;
			origin_y_input.value = origin_y;
			position_y_input.value = position_y;
			origin_x_input.dispatchEvent(new Event('change'));
			origin_y_input.dispatchEvent(new Event('change'));
			label_input.addEventListener("change", update_furniture, false);
			width_input.addEventListener("change", update_furniture, false);
			height_input.addEventListener("change", update_furniture, false);
			origin_x_input.addEventListener("change", update_furniture, false);
			position_x_input.addEventListener("change", update_furniture, false);
			origin_y_input.addEventListener("change", update_furniture, false);
			position_y_input.addEventListener("change", update_furniture, false);
			remove.addEventListener("click", ()=>{
				furniture_item_dom.remove();
				update_furniture();
			}, false);
			furniture_item_id++;
			update_furniture();
		}
		newWallAddFurnitureBtn.addEventListener("click", ()=>{ add_furniture() }, false);

		const project_id = document.body.getAttribute("data-pid");
		const project_slug = document.body.getAttribute("data-slug");
		const testbtn = document.querySelector("#testbtn");
		const saveNewWall = (e)=>{
			modal.classList.add("processing");
			const promise = window.DataControll.save(project_id, false);
			promise.then((res)=>{
				//生成に成功したら壁面ID（wid）付きパーマリンクが返ってくるので遷移
				if(res.data.indexOf(location.protocol+"//"+location.host) === 0 && res.data.indexOf("?wid=") !== -1){
					window.location.href = res.data;
					modal.classList.remove("processing");
				}
			});
			promise.catch((err) => {
				alert("壁面の作成に失敗しました\r\n"+err);
				window.location.reload();
				modal.classList.remove("processing");
			});
		}
		modalDoneBtn.addEventListener("click", saveNewWall, false);
		// testbtn.addEventListener("click", saveNewWall, false);


		
		

		const getValidation = ()=>{
			update_wallname();
			update_catno();
			update_dimension();
			update_panel();

			if(modalProgress == 1){
				return isWallNameValid && isStaffNameValid;
			}else if(modalProgress == 2){
				return isCatnoValid;
			}else if(modalProgress == 3){
				return isDimensionValid;
			}else if(modalProgress == 4 && isPanelMode){
				return isPanelValid;
			}
			return true;
		}

		const openNewWallModal = ()=>{
			this.Modal.openModal("#modal__newwall");
			window.project.walls[0] = this.Factory.blankWall();
			newWallData = window.project.walls[0];
			previewEditor1 = new Editor(previewDom1);
			previewEditor2 = new Editor(previewDom2);
			if(!getValidation()){
				modalNextBtn.classList.add("disable");	
			}else{
				modalNextBtn.classList.remove("disable");	
			}
		}
		newProjectBtn.addEventListener("click", openNewWallModal, false);
	}
}