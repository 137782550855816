
export default class DataControll{
	constructor(){
		this.initialize();
	}

	initialize(){
		const save = (pid, command=false)=>{
			/*
			*	command: 新規壁面か更新かを判定するフラグ
			*	trueなら更新
			*	falseなら新規壁面
			*/
			console.log(window.project);
			window.project.walls[0].last_update = (new Date()).toISOString();
			localStorage.setItem('whohw_alloc_editor_save', JSON.stringify(window.project));
			console.log("local saved");

			return new Promise((resolve, reject) => {
				const XHR = new XMLHttpRequest();
				XHR.addEventListener('load', (res) => {
					if(res.target.readyState == 4 && res.target.status == 200){
						if(res.target.response?.status == "ok"){
							resolve(res.target.response);
						}else{
							reject({status:"error", msg:'server error.\r\nresponse not valid'});
						}
					}else{
						reject({status:"error", msg:'server error.\r\n'+res.target.status});
					}
				});
				XHR.addEventListener('error', (res) => {
					reject({status:"error", msg:'XHR error.\r\n'+res});
				});
				XHR.open('POST', "/api/save_wall.php", true);
				XHR.setRequestHeader('Content-Type', 'application/json');
				XHR.responseType = 'json';
				const payload = {
					"pid": pid,
					"command": command,
					"json": window.project
				};
				XHR.send(JSON.stringify(payload));
			});
		}

		const restorefromlocal = ()=>{
			const data = localStorage.getItem('whohw_alloc_editor_save');
			if(data){
				window.project = JSON.parse(data);
			}
		}
		const loadFromServer = ()=>{}
		this.save = save;
		this.restoreFromLocal = restorefromlocal;
	}
}